import React, { lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Switch } from "react-router";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ru, uk, de, enGB } from "date-fns/locale";
import InfoPopup from "./components/InfoPopup/InfoPopup";
import { reconnectSocket } from "./utils/Socket";
import { getProfile } from "./redux/actions";
import { OpenRoute, PrivateRoute } from "./utils/Routes";
import MetaTags from "./components/MetaTags";
import i18next from "./utils/i18n";

const LoginLayout = lazy(() => import(/* webpackChunkName: "LoginLayout" */ "./layouts/LoginLayout/LoginLayout"));
const MainLayout = lazy(() => import(/* webpackChunkName: "MainLayout" */ "./layouts/MainLayout/MainLayout"));
const BlankPageLayout = lazy(() => import(/* webpackChunkName: "BlankPageLayout" */ "./layouts/BlankPageLayout"));
const SignUpLayout = lazy(() => import(/* webpackChunkName: "SignUpLayout" */ "./layouts/SignUpLayout/SignUpLayout"));
const ConfirmEmailLayout = lazy(() =>
  import(/* webpackChunkName: "ConfirmEmailLayout" */ "./layouts/ConfirmEmailLayout"),
);
const DownloadLayout = lazy(() => import(/* webpackChunkName: "DownloadLayout" */ "./layouts/DownloadLayout"));
const AboutUsLayout = lazy(() => import(/* webpackChunkName: "AboutUsLayout" */ "./layouts/AboutUsLayout"));
const PrivacyDocs = lazy(() => import(/* webpackChunkName: "PrivacyDocs" */ "./layouts/PrivacyDocs"));
const BuyPremiumAccountLayout = lazy(() =>
  import(/* webpackChunkName: "BuyPremiumAccountLayout" */ "./layouts/BuyPremiumAccountLayout"),
);
const FeedPreviewLayout = lazy(() =>
  import(/* webpackChunkName: "FeedPreviewLayout" */ "./layouts/FeedPreviewLayout/FeedPreviewLayout"),
);
const ChangeEmailLayout = lazy(() => import(/* webpackChunkName: "ChangeEmailLayout" */ "./layouts/ChangeEmailLayout"));
const PremiumExamplesLayout = lazy(() =>
  import(/* webpackChunkName: "PremiumExamplesLayout" */ "./layouts/PremiumExamplesLayout"),
);
const NotFoundLayout = lazy(() => import(/* webpackChunkName: "NotFoundLayout" */ "./layouts/NotFoundLayout"));
const DentalBotIntegrationLayout = lazy(() =>
  import(
    /* webpackChunkName: "DentalBotIntegrationLayout" */ "./layouts/DentalBotIntegrationLayout/DentalBotIntegrationLayout"
  ),
);

const theme = createTheme({
  palette: {
    primary: { main: "#223a68" },
    secondary: { main: "#f44336" },
  },
});

const App = () => {
  const dispatch = useDispatch();
  const { isAuth, userId, userLanguage } = useSelector(({ login, profile }) => ({
    isAuth: login.isAuth,
    userId: profile.id,
    userLanguage: profile.language,
  }));
  const [locale, setLocale] = useState(ru);

  useEffect(() => {
    if (isAuth && userLanguage) {
      i18next.changeLanguage(userLanguage).then(() => {
        switch (userLanguage) {
          case "en": {
            return setLocale(enGB);
          }
          case "uk": {
            return setLocale(uk);
          }
          case "de": {
            return setLocale(de);
          }
          default: {
            return setLocale(ru);
          }
        }
      });
    }
  }, [isAuth, userLanguage]);

  useEffect(() => {
    if (navigator.userAgent !== "ReactSnap") {
      dispatch(getProfile());
      reconnectSocket(userId);
    }
  }, [dispatch, userId]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
          <MetaTags />
          <Switch>
            {/* System routes */}
            <OpenRoute component={DentalBotIntegrationLayout} exact path="/dentalbot-integration/:secret" />
            <OpenRoute component={SignUpLayout} exact path="/sign_up/:token" />
            <OpenRoute component={SignUpLayout} exact path="/sign_up" />
            <OpenRoute component={ChangeEmailLayout} exact path="/change_email/:token" />
            <OpenRoute component={FeedPreviewLayout} exact path="/feedPreview/:token" />
            <OpenRoute component={DownloadLayout} exact path="/downloadFile/:token" />
            <OpenRoute component={ConfirmEmailLayout} exact path="/api/confirm-email/:token" />

            {/* Pages */}
            <OpenRoute component={PrivacyDocs} path={["/license-contract-offer", "/terms-of-use", "/privacy-policy"]} />
            <OpenRoute component={AboutUsLayout} path="/about-us" />
            <OpenRoute component={BuyPremiumAccountLayout} path="/buy-premium-account" />
            <OpenRoute
              component={PremiumExamplesLayout}
              path={["/virtual-online-lab", "/dental-clinic-website", "/digital-research", "virtual-shop"]}
            />
            {/*<OpenRoute component={PremiumExamplesLayout} path="/dental-clinic-website" />*/}
            {/*<OpenRoute component={PremiumExamplesLayout} path="/digital-research" />*/}
            {/*<OpenRoute component={PremiumExamplesLayout} path="/virtual-shop" />*/}

            <PrivateRoute auth={isAuth} component={BlankPageLayout} path="/project" />

            <PrivateRoute
              auth={isAuth}
              component={MainLayout}
              path={["/feed", "/profile", "/team", "/tasks", "/financial", "/events", "/administration", "/journal"]}
            />

            {isAuth && <Redirect from="/" to="/feed" />}

            <OpenRoute component={LoginLayout} exact path={["/", "/guide", "/our-products", "/videos"]} />

            <OpenRoute component={NotFoundLayout} />
          </Switch>
          <InfoPopup />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
