import React, { Suspense } from "react";
import propTypes from "prop-types";
import { Route } from "react-router";
import { Redirect } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import dentalBot from "../assets/images/dentalBotAnimated.gif";

export const Loader = () => (
  <img
    alt="loader"
    src={dentalBot}
    style={{
      position: "fixed",
      top: "calc(50% - 45px)",
      left: "calc(50% - 45px)",
      width: "90px",
      height: "90px",
      zIndex: 9,
    }}
  />
);

export const LoaderLinear = () => (
  <LinearProgress
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 9,
    }}
  />
);

export const OpenRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    )}
  />
);

export const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth === true ? (
        <Suspense fallback={<Loader />}>
          <Component {...props} />
        </Suspense>
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

PrivateRoute.propTypes = {
  auth: propTypes.bool.isRequired,
};
