import { API } from "../../utils/Api";
import moment from "moment";
import { push } from "connected-react-router";
import Logger from "../../utils/Logger";

export const SET_PROJECT_TYPE = "SET_PROJECT_TYPE";
export const SET_PROJECT_STEP = "SET_PROJECT_STEP";
export const SAVE_PROJECT_STEP2 = "SAVE_PROJECT_STEP2";
export const SET_PROJECT_PREVIEW = "SET_PROJECT_PREVIEW";
export const SET_PROJECT_DENTAL_PREVIEW = "SET_PROJECT_DENTAL_PREVIEW";
export const SET_PROJECT_DENTAL_DATA = "SET_PROJECT_DENTAL_DATA";
export const RESET_PROJECT_DATA = "RESET_PROJECT_DATA";
export const SET_ALL_PROJECT_DATA = "SET_ALL_PROJECT_DATA";
export const SET_DISABLED_STEPS = "SET_DISABLED_STEPS";

export function setProjectType(type) {
  return { type: SET_PROJECT_TYPE, payload: type };
}

export function setProjectStep(step) {
  return { type: SET_PROJECT_STEP, payload: step };
}

export function saveStep2(form) {
  return { type: SAVE_PROJECT_STEP2, payload: form };
}

export function setProjectPreview(preview) {
  return { type: SET_PROJECT_PREVIEW, payload: preview };
}

export function setProjectDentalData(type, activity) {
  return { type: SET_PROJECT_DENTAL_DATA, payload: { type, activity } };
}

export function setProjectDentalPreview(top, bottom) {
  return { type: SET_PROJECT_DENTAL_PREVIEW, payload: { top, bottom } };
}

export function resetProject() {
  return { type: RESET_PROJECT_DATA };
}

export function sendProject() {
  return (dispatch, getState) => {
    const currentProject = { ...getState().newProject };
    // dispatch(startLogin());

    const project = {
      projectId: currentProject.projectId,
      projectType: currentProject.currentType,
      cardNumber: currentProject.currentForm.cardNumber,
      clinic: currentProject.currentForm.clinic,
      patient_first_name: currentProject.currentForm.patientFirstName,
      patient_last_name: currentProject.currentForm.patientLastName,
      patient_patronymic: currentProject.currentForm.patientPatronymic,
      dentalLaboratory: currentProject.currentForm.dentalLaboratory,
      leadingDoctor: currentProject.currentForm.leadingDoctor,
      patientGender: currentProject.currentForm.patientGender,
      additionalDoctor: currentProject.currentForm.additionalDoctor,
      firstAdmissionDate: moment(currentProject.currentForm.firstAdmissionDate).format("YYYY-MM-DD"),
      patientBirthday: moment(currentProject.currentForm.patientBirthday).format("YYYY-MM-DD"),
      patientResidence: currentProject.currentForm.patientResidence,
      patientPhone: currentProject.currentForm.patientPhone,
      patientEmail: currentProject.currentForm.patientEmail,
      patientPassportNumber: currentProject.currentForm.patientPassportNumber,
      patientPassportDate: moment(currentProject.currentForm.patientPassportDate).format("YYYY-MM-DD"),
      patientPassportIssued: currentProject.currentForm.patientPassportIssued,
      patientInsuranceNumber: currentProject.currentForm.patientInsuranceNumber,
      patientInsuranceDate: moment(currentProject.currentForm.patientInsuranceDate).format("YYYY-MM-DD"),
      patientInsuranceInfo: currentProject.currentForm.patientInsuranceInfo,
      preview: currentProject.projectPreview,
      upperJawPreview: currentProject.upperJawPreview,
      lowerJawPreview: currentProject.lowerJawPreview,
      projectData: currentProject.projectData,
    };
    if (project.projectId) {
      return API.patch(`feed/project/${project.projectId}/`, project).then(
        (response) => {
          Logger.info(response);
          // Logger.info(response);
          dispatch(resetProject());
          // dispatch(obtainProfileData(response.data.data.account));
          dispatch(push("/feed"));
        },
        (error) => {
          Logger.info(error);
          // dispatch(obtainLoginError(error));
        },
      );
    } else {
      return API.post("feed/project", project).then(
        (response) => {
          Logger.info(response);
          // Logger.info(response);
          dispatch(resetProject());
          // dispatch(obtainProfileData(response.data.data.account));
          dispatch(push("/feed"));
        },
        (error) => {
          Logger.info(error);
          // dispatch(obtainLoginError(error));
        },
      );
    }
  };
}

function setAllProjectData(data) {
  return {
    type: SET_ALL_PROJECT_DATA,
    payload: data,
  };
}

function setDisabledSteps(steps) {
  return {
    type: SET_DISABLED_STEPS,
    payload: steps,
  };
}

export const editProjectFormula = (projectId) => (dispatch) => {
  return API.get(`feed/object/${projectId}`).then(
    (response) => {
      Logger.info(response.data);

      const savedProject = {
        projectId: response.data.id,
        projectType: response.data.projectType,
        currentForm: {
          cardNumber: response.data.cardNumber,
          clinic: response.data.clinic,
          patientFirstName: response.data.patient_first_name,
          patientLastName: response.data.patient_last_name,
          patientPatronymic: response.data.patient_patronymic,
          dentalLaboratory: response.data.dentalLaboratory,
          leadingDoctor: response.data.leadingDoctor,
          patientGender: response.data.patientGender,
          additionalDoctor: response.data.additionalDoctor,
          firstAdmissionDate: response.data.firstAdmissionDate,
          patientBirthday: response.data.patientBirthday,
          patientResidence: response.data.patientResidence,
          patientPhone: response.data.patientPhone,
          patientEmail: response.data.patientEmail,
          patientPassportNumber: response.data.patientPassportNumber,
          patientPassportDate: response.data.patientPassportDate,
          patientPassportIssued: response.data.patientPassportIssued,
          patientInsuranceNumber: response.data.patientInsuranceNumber,
          patientInsuranceDate: response.data.patientInsuranceDate,
          patientInsuranceInfo: response.data.patientInsuranceInfo,
        },
        projectData: response.data.projectData,
        projectPreview: response.data.preview,
      };

      dispatch(setAllProjectData(savedProject));

      dispatch(setDisabledSteps([1]));
      dispatch(setProjectStep(2));
      // Logger.info(response);
      // dispatch(resetProject());
      // dispatch(obtainProfileData(response.data.data.account));
      dispatch(push("/project"));
    },
    (error) => {
      Logger.info(error);
      // dispatch(obtainLoginError(error));
    },
  );
};
