import { API } from "../../utils/Api.js";
import Logger from "../../utils/Logger";

export const OBTAIN_ADMINISTRATION_DATA = "OBTAIN_ADMINISTRATION_DATA";
export const OBTAIN_ADMINISTRATION_ERROR = "OBTAIN_ADMINISTRATION_ERROR";

function obtainAdministrationData(data, nextCursor) {
  return {
    type: OBTAIN_ADMINISTRATION_DATA,
    payload: { data, nextCursor },
  };
}

function obtainAdministrationError(error) {
  return {
    type: OBTAIN_ADMINISTRATION_ERROR,
    payload: error,
  };
}

export const getAdministrationList = (page) => (dispatch, getState) => {
  if (page === 1) {
    dispatch(obtainAdministrationData([], null));
  }
  const nextCursor = getState().team.teamListNextCursor;
  return API.get("/user/contacts", { params: { cursor: nextCursor } }).then(
    (response) => {
      Logger.info(response.data);
      dispatch(obtainAdministrationData(response.data.results, response.data.next));
    },
    (error) => {
      Logger.info(error);
      dispatch(obtainAdministrationError(error));
    },
  );
};
