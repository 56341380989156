import React, { useMemo } from "react";
import { IconButton, Snackbar } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setInfoPopup } from "../../redux/actions";
import { INFO_POPUP_TYPES } from "../../constants";

function InfoPopup() {
  const { t: _t } = useTranslation();

  const dispatch = useDispatch();

  const { type, message } = useSelector(({ infoPopup }) => infoPopup);

  const { popupType, popupMessage } = useMemo(() => {
    if (!type && !message) {
      return {
        popupType: null,
        popupMessage: "",
      };
    }
    switch (type) {
      case INFO_POPUP_TYPES.SUCCESS_SAVED:
        return {
          popupType: INFO_POPUP_TYPES.SUCCESS,
          popupMessage: _t("Сохранено"),
        };
      case INFO_POPUP_TYPES.ERROR_GLOBAL:
        return {
          popupType: INFO_POPUP_TYPES.ERROR,
          popupMessage: _t("Ошибка. Попробуйте позже или обратитесь в техподдержку"),
        };
      case INFO_POPUP_TYPES.ERROR_500:
        return {
          popupType: INFO_POPUP_TYPES.ERROR,
          popupMessage: _t("Ошибка сервера, попробуйте позже или обратитесь в техподдержку"),
        };
      default:
        return {
          popupType: type,
          popupMessage: message,
        };
    }
  }, [_t, message, type]);

  function closeInfoPopup() {
    dispatch(setInfoPopup(null, ""));
  }

  return (
    <Snackbar
      style={{ zIndex: 1600 }}
      open={!!popupMessage}
      key={message}
      className={`snackbar_${popupType}`}
      message={popupMessage}
      onClose={(e, reason) => {
        if (reason !== "clickaway") closeInfoPopup();
      }}
      action={
        <IconButton onClick={closeInfoPopup} size="small">
          <Close fontSize="small" />
        </IconButton>
      }
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={3000}
      transitionDuration={{ enter: 200, exit: 0 }}
    />
  );
}

export default InfoPopup;
