import {
  EXIT,
  RESET_PROJECT_DATA,
  SAVE_PROJECT_STEP2,
  SET_ALL_PROJECT_DATA,
  SET_DISABLED_STEPS,
  SET_PROJECT_DENTAL_DATA,
  SET_PROJECT_DENTAL_PREVIEW,
  SET_PROJECT_PREVIEW,
  SET_PROJECT_STEP,
  SET_PROJECT_TYPE,
} from "../actions";

const initialState = {
  isLoading: false,
  currentType: "", // DENTAL TOTAL default: ""
  currentStep: 1, // 1-3 default 1
  disabledSteps: [],
  projectNavigation: true,
  projectId: null,
  currentForm: {
    cardNumber: "",
    clinic: "",
    patientFirstName: "",
    patientLastName: "",
    patientPatronymic: "",
    dentalLaboratory: "",
    leadingDoctor: "",
    patientGender: "",
    additionalDoctor: "",
    firstAdmissionDate: new Date(),
    patientBirthday: new Date(),
    patientResidence: "",
    patientPhone: "",
    patientEmail: "",
    patientPassportNumber: "",
    patientPassportDate: new Date(),
    patientPassportIssued: "",
    patientInsuranceNumber: "",
    patientInsuranceDate: new Date(),
    patientInsuranceInfo: "",
  },
  projectPreview: null,
  upperJawPreview: null,
  lowerJawPreview: null,
  projectData: {
    tooth: [],
    gaps: [],
    prosthesis: [],
    jointDisease: [],
    mandibularCanal: [],
    sinuses: [],
    toothStyle: "",
    toothColor: { colorSystem: "", colorName: "", neck: "", pigmentation: "", cuttingEdge: "" },
    engelClass: "",
    boneType: "",
    toothErasure: "",
    mucosalDisease: "",
  },
  errors: [],
};

export default function newProjectReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECT_TYPE:
      return { ...state, currentType: action.payload };
    case SET_PROJECT_STEP:
      return { ...state, currentStep: action.payload };
    case SAVE_PROJECT_STEP2:
      return { ...state, currentForm: action.payload };
    case SET_PROJECT_PREVIEW:
      return { ...state, projectPreview: action.payload };
    case SET_PROJECT_DENTAL_DATA:
      return { ...state, projectData: { ...state.projectData, [action.payload.type]: action.payload.activity } };
    case SET_PROJECT_DENTAL_PREVIEW:
      return { ...state, upperJawPreview: action.payload.top, lowerJawPreview: action.payload.bottom };
    case SET_ALL_PROJECT_DATA:
      return {
        ...state,
        projectId: action.payload.projectId,
        currentType: action.payload.projectType,
        currentForm: action.payload.currentForm,
        projectPreview: action.payload.projectPreview,
        projectData: action.payload.projectData,
      };
    case SET_DISABLED_STEPS:
      return { ...state, disabledSteps: action.payload };
    case RESET_PROJECT_DATA:
    case EXIT:
      return initialState;
    default:
      return state;
  }
}
