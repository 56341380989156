import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ru from "../locales/ru.json";
import uk from "../locales/uk.json";
import en from "../locales/en.json";
import de from "../locales/de.json";
// the translations

export const availableLanguages = [
  { key: "ru", title: "Русский", titleShort: "RU" },
  { key: "uk", title: "Українська", titleShort: "UK" },
  { key: "en", title: "English", titleShort: "EN" },
  { key: "de", title: "Deutsch", titleShort: "DE" },
];

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    lng: process.env.REACT_APP_LANG ? process.env.REACT_APP_LANG : "ru",
    resources: {
      ru: { translation: ru },
      uk: { translation: uk },
      en: { translation: en },
      de: { translation: de },
    },
    load: "unspecific",
    whitelist: ["en", "ru", "uk", "de"],
    fallbackLng: ["en", "ru", "uk", "de"],
    nsSeparator: "::",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
