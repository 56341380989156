export const TOGGLE_TOP_BAR = "TOGGLE_TOP_BAR";

export const TopBarForceActions = {
  OPEN: "open",
  CLOSE: "close",
};

export const toggleTopBar = (forceAction) => (dispatch, getState) => {
  let isClosed;
  switch (forceAction) {
    case TopBarForceActions.OPEN: {
      isClosed = false;
      break;
    }
    case TopBarForceActions.CLOSE: {
      isClosed = true;
      break;
    }
    default:
      isClosed = !getState().topBar.isSideBarClosed;
  }

  return dispatch({
    type: TOGGLE_TOP_BAR,
    payload: isClosed,
  });
};
