import { EXIT, TOGGLE_LIBRARY_PAGE } from "../actions";

const initialState = {
  isOpen: false,
  libraryItem: null,
};

export default function libraryReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_LIBRARY_PAGE:
      return { ...state, isOpen: action.payload.isOpen, libraryItem: action.payload.libraryItem };
    case EXIT:
      return initialState;
    default:
      return state;
  }
}
