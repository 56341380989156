import {
  CLEAR_CHAT_LIST,
  EXIT,
  GET_CONTACTS_LENGTH,
  LOAD_MORE_CHAT_LIST,
  OBTAIN_CHAT_LIST_DATA,
  OBTAIN_CHAT_LIST_ERROR,
  START_CHAT_LIST_DATA,
} from "../actions";

const initialState = {
  chatList: [],
  chatListCursor: null,
  chatListError: [],
  chatListCount: 0,
  loading: false,
};

export default function chatListReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_CHAT_LIST:
      return { ...state, chatList: [] };
    case OBTAIN_CHAT_LIST_DATA:
      return {
        ...state,
        chatList: action.payload.data,
        chatListCursor: action.payload.nextCursor,
        chatListError: [],
        chatListCount: action.payload.count,
      };
    case OBTAIN_CHAT_LIST_ERROR:
      return { ...state, chatList: [], chatListError: action.payload };
    case GET_CONTACTS_LENGTH:
      return { ...state, chatListCount: action.payload };
    case START_CHAT_LIST_DATA:
      return { ...state, loading: action.payload };
    case LOAD_MORE_CHAT_LIST:
      return {
        ...state,
        chatList: [...state.chatList, ...action.results],
        chatListCursor: action.next,
        loading: action.loading,
      };
    case EXIT:
      return initialState;
    default:
      return state;
  }
}
