import { Views } from "react-big-calendar";

export const RANGE = [15, 30, 60, 90];

export const NAVIGATION_CONSTANTS = {
  PREV: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
};

export const VIEWS = [Views.MONTH, Views.WEEK, Views.DAY];

export const APPOINTMENT_DIALOG_CURRENT_VIEW = {
  VIEW: "view",
  EDIT: "edit",
};

export const GET_PARAMS_JOURNAL = {
  VIEW: "view",
  DATE: "date",
  START_DATE: "start_date",
  END_DATE: "end_date",
  DOCTOR: "doctor",
  TITLE: "title",
  PATIENT: "patient",
  JOURNAL_ID: "journal_id",
};
