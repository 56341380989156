import { API } from "../../utils/Api";
import { setInfoPopup } from "./infoPopupAction";
import { INFO_POPUP_TYPES } from "../../constants";

// ============ updateSingleImage
export const UPDATE_SINGLE_IMAGE_START = "UPDATE_SINGLE_IMAGE_START";
export const UPDATE_SINGLE_IMAGE_SUCCESS = "UPDATE_SINGLE_IMAGE_SUCCESS";

export const updateSingleImageStart = () => ({
  type: UPDATE_SINGLE_IMAGE_START,
});

export const updateSingleImageSuccess = (imagePreviewArrayNewItem) => ({
  type: UPDATE_SINGLE_IMAGE_SUCCESS,
  payload: { imagePreviewArrayNewItem },
});

export const updateSingleImage = (fileId, fields, endpoint) => {
  return async (dispatch) => {
    dispatch(updateSingleImageStart());
    try {
      const { data: updatedImage } = await API.patch(`/${endpoint}/file/${fileId}`, { file_obj: { ...fields } });
      dispatch(
        updateSingleImageSuccess({
          title: updatedImage.name,
          img: updatedImage.image_obj.original,
          imgTiff: updatedImage.viewer_image,
        }),
      );
    } catch (e) {
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
    }
  };
};

// ============ updateImageInSlider
export const UPDATE_IMAGE_IN_SLIDER_START = "UPDATE_IMAGE_IN_SLIDER_START";
export const UPDATE_IMAGE_IN_SLIDER_SUCCESS = "UPDATE_IMAGE_IN_SLIDER_SUCCESS";

const updateImageInSliderStart = () => ({
  type: UPDATE_IMAGE_IN_SLIDER_START,
});

const updateImageInSliderSuccess = (newImagePreviewArray) => ({
  type: UPDATE_IMAGE_IN_SLIDER_SUCCESS,
  payload: { newImagePreviewArray },
});

function makeImagePreviewArrayNewItemFromUpdatedImageDTO(dto) {
  return {
    id: dto.id,
    title: dto.title,
    img: dto.imgUpload,
    imgPreview: dto.thumbnail,
    imgTiff: dto.viewer_image,
  };
}

export const updateImageInSlider = (sliderId, imageId, fields) => {
  return async (dispatch, getState) => {
    dispatch(updateImageInSliderStart());
    try {
      const { data: updatedSlider } = await API.patch(`/project/object/${sliderId}/image_update/${imageId}`, fields);
      const updatedImage = Object.values(updatedSlider)
        .map(
          (updateSlideValues) =>
            Array.isArray(updateSlideValues) &&
            updateSlideValues.length &&
            updateSlideValues.find((el) => el.id === imageId),
        )
        .find((el) => !!el);
      const oldImagePreviewArray = getState().feed.imagePreviewArray;
      const newImagePreviewArray = oldImagePreviewArray.map((el) =>
        el.id === imageId ? makeImagePreviewArrayNewItemFromUpdatedImageDTO(updatedImage) : el,
      );

      dispatch(updateImageInSliderSuccess(newImagePreviewArray));
    } catch (e) {
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
    }
  };
};
