import axios from "axios";
import { setInfoPopup } from "../redux/actions";
import store from "../redux/store";
import { INFO_POPUP_TYPES } from "../constants";

const STATUS_CODE_400 = 400;
const STATUS_CODE_401 = 401;
const STATUS_CODE_500 = 500;

export const API = axios.create({
  baseURL: process.env.REACT_APP_URL_COLLEAGIAL,
});

API.interceptors.request.use(
  function (config) {
    config.headers.common[process.env.REACT_APP_AUTH_HEADER] = localStorage.getItem(process.env.REACT_APP_AUTH_HEADER);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

API.interceptors.response.use(
  function (response) {
    if (response.headers[process.env.REACT_APP_AUTH_HEADER]) {
      localStorage.setItem(process.env.REACT_APP_AUTH_HEADER, response.headers[process.env.REACT_APP_AUTH_HEADER]);
    }
    return response;
  },
  function (error) {
    let responseError = [];
    if (error && error.hasOwnProperty("response") && error.response?.hasOwnProperty("status")) {
      if (STATUS_CODE_401 === error.response?.status) {
        localStorage.removeItem(process.env.REACT_APP_AUTH_HEADER);
        responseError = error.response?.data.error;
        return Promise.reject(responseError);
      } else if (STATUS_CODE_400 === error.response?.status) {
        responseError = error.response?.data.data.error.map((el) => ({
          error: el.message,
        }));
        return Promise.reject(responseError);
      } else if (STATUS_CODE_500 === error.response?.status) {
        store.dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_500));
      }
    }

    return Promise.reject(error);
  },
);

export const API_SERVICE = axios.create({
  baseURL: process.env.REACT_APP_URL_COLLEAGIAL_SERVICE,
});
