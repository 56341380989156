import { ACCESS_LOADING } from "../actions";

const initialState = {
  loading: false,
};

export default function accessReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACCESS_LOADING:
      return { ...state, loading: payload };
    default:
      return state;
  }
}
