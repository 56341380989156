import { EXIT, OBTAIN_FINANCIAL_DATA, OBTAIN_FINANCIAL_ERROR, OBTAIN_FINANCIAL_LOADING } from "../actions";

const initialState = {
  financialList: [],
  financialListError: [],
  financialListLoading: false,
  financialListNext: null,
  financialListTotalSum: null,
};

export default function financialReducer(state = initialState, action) {
  switch (action.type) {
    case OBTAIN_FINANCIAL_DATA:
      return {
        ...state,
        financialList: action.payload.data,
        financialListError: [],
        financialListNext: action.payload.next,
        financialListTotalSum: action.payload.totalSum,
      };
    case OBTAIN_FINANCIAL_ERROR:
      return {
        ...state,
        financialList: [],
        financialListError: action.payload,
        financialListNext: null,
      };
    case OBTAIN_FINANCIAL_LOADING:
      return {
        ...state,
        financialListLoading: action.payload,
      };
    case EXIT:
      return initialState;
    default:
      return state;
  }
}
