export const ALL_CARDS = 15;
export const PREMIUM_EXTRA_MONTHS = 2;

export const INPUT_TYPES = {
  NUMBER: "number",
  PERCENT: "percent",
};

export const INFO_POPUP_TYPES = {
  SUCCESS: "success",
  SUCCESS_SAVED: "success_saved",
  ERROR: "error",
  ERROR_GLOBAL: "error_global",
  ERROR_500: "error_500",
  INFO: "info",
};
