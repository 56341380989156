import {
  EXIT,
  RESET_WINDOW_THUMBNAIL_DATA,
  SET_3D_DENTAL,
  SET_3D_STL,
  TOGGLE_WINDOW_THUMBNAIL,
  TOGGLE_WINDOW_THUMBNAIL_FULLSCREEN,
} from "../actions";

const initialState = {
  isOpen: false,
  isMini: true,
  currentView: "",
  projectDentalData: {},
  stlData: [],
};

export default function windowThumbnailReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_WINDOW_THUMBNAIL:
      return { ...state, isOpen: action.payload, isMini: true };
    case TOGGLE_WINDOW_THUMBNAIL_FULLSCREEN:
      return { ...state, isOpen: true, isMini: action.payload };
    case RESET_WINDOW_THUMBNAIL_DATA:
      return { ...state, currentView: "", projectDentalData: {}, stlData: [] };
    case SET_3D_DENTAL:
      return { ...state, currentView: "projectDental", projectDentalData: action.payload };
    case SET_3D_STL:
      return { ...state, currentView: "stlPreview", stlData: action.payload };
    case EXIT:
      return initialState;
    default:
      return state;
  }
}
