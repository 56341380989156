import io from "socket.io-client";
import Logger from "./Logger";
import {
  getChatList,
  getInviteList,
  getProfile,
  getProjectTasks,
  getSingleChatBoxMessage,
  getSingleFeed,
  getSingleProjectContent,
  getTeamList,
  getUpcomingTasks,
  getUserUnreadNotifications,
  removeSingleFeed,
  removeSingleProjectContent,
  searchFeedItem,
  setIsNewFeedItemAdded,
} from "../redux/actions";

let socket, socketStore;

export const getSocket = () => socket;
export const closeSocket = () => (socket ? socket.close() : null);
export const createSocketConnection = (store) => {
  socketStore = store;

  if (socket) {
    socket.close();
  }
  socket = io(`${process.env.REACT_APP_WSS}/notifications`);

  attachServiceCommands();

  return socket;
};
export const reconnectSocket = (userId) => {
  if (socket) {
    socket.close();
  }
  socket = io(`${process.env.REACT_APP_WSS}/notifications?id=${userId}`);

  // Service console
  // attachServiceCommands();
  attachAppCommands();

  return socket;
};

const attachAppCommands = () => {
  // Chatbox event
  socket.on("message", (data) => {
    Logger.info("new socket message", data);
    socketStore.dispatch(getChatList(1)); // For mini list
    socketStore.dispatch(getTeamList(1)); // For right bar list
    const chatBox = socketStore.getState().chatBox;
    if (chatBox.isShow && chatBox.currentUser === data.sender_id) {
      socketStore.dispatch(getSingleChatBoxMessage(data.message_id));
      socketStore.dispatch(searchFeedItem({ search: "", type: "project", page: 1 }));
    }
  });

  // Appointments event
  socket.on("appointments", (data) => {
    const currentUserID = socketStore.getState().profile?.id;
    Logger.info("New socket appointments", data);
    switch (data.type) {
      case "AppointmentCreate":
        if (data.project) {
          socketStore.dispatch(getProjectTasks(data.project));
        }
        if (data.sender_id !== currentUserID) {
          socketStore.dispatch(getUpcomingTasks());
        }
        break;
      case "AppointmentEdit":
        if (data.project) {
          socketStore.dispatch(getProjectTasks(data.project));
        }
        if (data.sender_id !== currentUserID) {
          socketStore.dispatch(getUpcomingTasks());
        }

        break;
      case "AppointmentDelete":
        if (data.project) {
          socketStore.dispatch(getProjectTasks(data.project));
        }
        if (data.sender_id !== currentUserID) {
          socketStore.dispatch(getUpcomingTasks());
        }
        break;
      default:
        Logger.info("Uncatched socket appointments event", data);
      // TODO add isOpen feed statement with notifications
      // socketStore.dispatch(getSingleFeed(data.feed_id));
    }
  });

  // Feed event
  socket.on("feed", (data) => {
    Logger.info("New socket feed", data);
    Logger.info("obtaint socket feed type", data.type);

    const profileID = socketStore.getState().profile?.id;
    const isPremium = socketStore.getState().profile?.billing_data?.premium;

    switch (data.type) {
      case "UserSizeUpdate":
        socketStore.dispatch(getProfile());
        break;
      case "FeedImageCreate":
      case "FeedVideoCreate":
      case "FeedProjectCreate":
      case "FeedMessageCreate":
      case "FeedFileCreate":
      case "FeedBillCreate":
      case "FeedFileStlCreate":
      case "FeedDicomCreate":
        socketStore.dispatch(getSingleFeed(data.feed_id));
        if (!isPremium) {
          socketStore.dispatch(getProfile());
        }
        if (data.sender_id === profileID) {
          socketStore.dispatch(setIsNewFeedItemAdded(true));
        }

        break;
      case "FeedImageUpdate":
      case "FeedProjectUpdate":
      case "FeedMessageUpdate":
      case "FeedFileUpdate":
      case "FeedVideoUpdate":
      case "FeedFileStlUpdate":
      case "FeedDicomUpdate":
      case "FeedBillUpdate":
      case "FeedAccessGranted":
        const itemId = data.feed_id ? data.feed_id : data.object_id;
        socketStore.dispatch(getSingleFeed(itemId));
        if (!isPremium) {
          socketStore.dispatch(getProfile());
        }
        break;
      case "FeedProjectDelete":
      case "FeedMessageDelete":
      case "FeedImageDelete":
      case "FeedFileDelete":
      case "FeedVideoDelete":
      case "FeedFileStlDelete":
      case "FeedDicomDelete":
      case "FeedBillDelete":
        socketStore.dispatch(removeSingleFeed(data.feed_id));
        if (!isPremium) {
          socketStore.dispatch(getProfile());
        }
        break;

      case "FeedAccessRemoved":
        if (data.target_user === profileID) {
          socketStore.dispatch(removeSingleFeed(data.feed_id));
        }
        socketStore.dispatch(getSingleFeed(data.feed_id));
        break;

      case "TreatmentPlanApply":
        socketStore.dispatch(getSingleFeed(data.project_id));
        break;

      case "ProjectMessageDelete":
      case "ProjectFileDelete":
      case "ProjectVideoDelete":
      case "ProjectImageDelete":
      case "ProjectFileStlDelete":
      case "ProjectPhotoProtocolDelete":
      case "ProjectXrayProtocolDelete":
      case "ProjectSliderDelete":
      case "ProjectBillDelete":
      case "TreatmentPlanDelete":
      case "ProjectPatientModels3DDelete":
      case "ProjectPatientSetup3DDelete":
      case "ProjectPatientSlider3DDelete":
        if (socketStore.getState().feed.feedList[data.project_id]) {
          socketStore.dispatch(getSingleFeed(data.project_id));
          socketStore.dispatch(removeSingleProjectContent(data.project_id, data.object_id));
        }
        break;

      case "ProjectMessageAddIncognitoUser":
      case "ProjectImageAddIncognitoUser":
      case "ProjectVideoAddIncognitoUser":
      case "ProjectPhotoProtocolAddIncognitoUser":
      case "ProjectXrayProtocolAddIncognitoUser":
      case "ProjectSliderAddIncognitoUser":
      case "ProjectBillAddIncognitoUser":
      case "TreatmentPlanAddIncognitoUser":
      case "ProjectPatientModels3DAddIncognitoUser":
      case "ProjectPatientSetup3DAddIncognitoUser":
      case "ProjectPatientSlider3DAddIncognitoUser":
      case "ProjectFileAddIncognitoUser":
        socketStore.dispatch(getSingleProjectContent(data.project_id, data.object_id));
        break;

      case "ProjectMessageRemoveIncognitoUser":
      case "ProjectImageRemoveIncognitoUser":
      case "ProjectVideoRemoveIncognitoUser":
      case "ProjectPhotoProtocolRemoveIncognitoUser":
      case "ProjectXrayProtocolRemoveIncognitoUser":
      case "ProjectSliderRemoveIncognitoUser":
      case "ProjectBillRemoveIncognitoUser":
      case "TreatmentPlanRemoveIncognitoUser":
      case "ProjectPatientModels3DRemoveIncognitoUser":
      case "ProjectPatientSetup3DRemoveIncognitoUser":
      case "ProjectPatientSlider3DRemoveIncognitoUser":
      case "ProjectFileRemoveIncognitoUser":
        socketStore.dispatch(getSingleProjectContent(data.project_id, data.object_id));
        if (data.sender_id !== profileID) {
          socketStore.dispatch(removeSingleProjectContent(data.project_id, data.object_id));
        }
        break;

      case "ProjectMessageCreate":
      case "ProjectPhotoProtocolCreate":
      case "ProjectPhotoProtocolUpdate":
      case "ProjectXrayProtocolCreate":
      case "ProjectXrayProtocolUpdate":
      case "ProjectSliderCreate":
      case "ProjectSliderUpdate":
      case "ProjectPatientModels3DCreate":
      case "ProjectPatientSetup3DCreate":
      case "ProjectPatientSlider3DCreate":
        if (socketStore.getState().feed.feedList[data.project_id]) {
          if (!isPremium) {
            socketStore.dispatch(getProfile());
          }
          // socketStore.dispatch(toggleImagePreview({ isShow: false }));
          socketStore.dispatch(getSingleFeed(data.project_id));
          socketStore.dispatch(getSingleProjectContent(data.project_id, data.object_id));
        }
        break;

      case "ProjectMessageUpdate":
      case "ProjectFileCreate":
      case "ProjectFileUpdate":
      case "ProjectVideoCreate":
      case "ProjectVideoUpdate":
      case "ProjectImageCreate":
      case "ProjectImageUpdate":
      case "ProjectFileStlCreate":
      case "ProjectFileStlUpdate":
      case "ProjectBillCreate":
      case "ProjectBillUpdate":
      case "TreatmentPlanCreate":
      case "TreatmentPlanUpdate":
      case "ProjectPatientModels3DUpdate":
      case "ProjectPatientSetup3DUpdate":
      case "ProjectPatientSlider3DUpdate":
        if (socketStore.getState().feed.feedList[data.project_id]) {
          if (!isPremium) {
            socketStore.dispatch(getProfile());
          }
          socketStore.dispatch(getSingleFeed(data.project_id));
          socketStore.dispatch(getSingleProjectContent(data.project_id, data.object_id, profileID === data.sender_id));
        }
        break;

      case "AddIncognitoUser":
      case "RemoveIncognitoUser":
      case "ToggleIncognitoMode":
        let currentProject = { ...socketStore.getState().feed.feedList[data.project_id] };
        if (currentProject) {
          socketStore.dispatch(getSingleFeed(data.project_id));
          if (
            data.type === "AddIncognitoUser" ||
            (data.type === "ToggleIncognitoMode" && data.incognito_mode === false) ||
            profileID === data.sender_id ||
            profileID === currentProject.sender.id
          ) {
            socketStore.dispatch(
              getSingleProjectContent(data.project_id, data.object_id, profileID === data.sender_id),
            );
          } else {
            socketStore.dispatch(
              removeSingleProjectContent(data.project_id, data.object_id, profileID === data.sender_id),
            );
          }
        }
        break;
      case "ProjectDocumentCreated":
        window.open(data.url, "_self");
        break;
      default:
        if (profileID !== data.sender_id) {
          // TODO add isOpen feed statement with notifications
          socketStore.dispatch(getSingleFeed(data.feed_id));
        }
    }
  });

  // Team event
  socket.on("team", (data) => {
    // TODO Refactor =)
    switch (data.type) {
      case "NewAddToContactsRequest":
        socketStore.dispatch(getInviteList());
        break;
      case "RejectContactsRequest":
      case "AcceptContactsRequest":
        socketStore.dispatch(getInviteList());
        socketStore.dispatch(getTeamList(1));
        break;
      case "RemoveContacts":
        socketStore.dispatch(getTeamList(1));
        break;
      default:
    }
  });

  // Payments event
  socket.on("payments", (data) => {
    Logger.info("New socket [payments]:", data);
    switch (data.type) {
      case "ProfileUpdate":
        socketStore.dispatch(getProfile());
        break;
      default:
        Logger.info("not handled [payments] event", data);
    }
  });

  // Notifications
  socket.on("notifications", (data) => {
    Logger.info("New socket notifications", data);
    switch (data.type) {
      case "NewNotification":
        Logger.info("emit notify");
        socketStore.dispatch(getUserUnreadNotifications());
        break;
      default:
        Logger.info("not handled team event", data);
    }
  });
};

export const socketEmmit = (type, payload) => {
  if (socket) {
    socket.emit(type, payload);
  }
};

const attachServiceCommands = () => {
  // Service commands
  // socket.on("connect", () => {
  //   Logger.info("SOCKET CONNECTION connected (openned) => ", socket);
  // });
  // socket.on("disconnect", () => {
  //   Logger.info("SOCKET CONNECTION disconnect => ", socket ? socket.connected : null);
  // });
  // socket.on("connect_error", (error) => {
  //   Logger.info("SOCKET connect_error => ", JSON.stringify(error));
  // });
  // socket.on("connect_timeout", (timeout) => {
  //   Logger.info("SOCKET connect_timeout => ", timeout);
  // });
  // socket.on("error", (error) => {
  //   Logger.info("SOCKET error => ", error);
  // });
  // socket.on("ping", () => {
  //   Logger.info("SOCKET ping");
  // });
  // socket.on("pong", (latency) => {
  //   Logger.info("SOCKET pong => ", latency);
  // });
  //
  // // Reconnect
  // socket.on("reconnect", (attempt) => {
  //   Logger.info("SOCKET reconnect => ", attempt);
  // });
  // socket.on("reconnect_attempt", (res) => {
  //   Logger.info("SOCKET reconnect_attempt => ", res);
  // });
  // socket.on("reconnecting", (attempt) => {
  //   Logger.info("SOCKET reconnecting => ", attempt);
  // });
  // socket.on("reconnect_error", (error) => {
  //   Logger.info("SOCKET reconnect_error => ", JSON.stringify(error));
  // });
  // socket.on("reconnect_failed", () => {
  //   Logger.info("SOCKET reconnect_failed => ");
  // });
};
