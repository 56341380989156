import { EXIT, REPOST_ERROR, REPOST_LOADING, REPOST_SUCCESS } from "../actions";

const initialState = {
  loading: false,
  error: null,
};

export default function repostReducer(state = initialState, action) {
  switch (action.type) {
    case REPOST_LOADING:
      return { ...state, loading: true };
    case REPOST_SUCCESS:
      return { ...state, loading: false };
    case REPOST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case EXIT:
      return initialState;
    default:
      return state;
  }
}
