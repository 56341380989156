import { OBTAIN_LOGIN_DATA, OBTAIN_LOGIN_ERROR, SET_AUTH, SET_FIRST_LOAD_HELPER, START_LOGIN } from "../actions";

const initialState = {
  isLoading: false,
  isAuth: !!localStorage.getItem(process.env.REACT_APP_AUTH_HEADER),
  errors: [],
  err: null,
  isFirstLoadHelper: false,
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH:
      return { ...state, isAuth: action.payload };
    case START_LOGIN:
      return {
        ...state,
        isLoading: true,
        err: null,
        isAuth: false,
        errors: [],
      };
    case OBTAIN_LOGIN_DATA:
      return {
        ...state,
        isLoading: false,
        isAuth: true,
        err: null,
        errors: [],
      };
    case OBTAIN_LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        isAuth: false,
        errors: action.payload,
        err: action.err,
      };
    case SET_FIRST_LOAD_HELPER:
      return {
        ...state,
        isFirstLoadHelper: action.payload,
      };
    default:
      return state;
  }
}
