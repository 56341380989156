import { API } from "../../utils/Api";
import Logger from "../../utils/Logger";

export const GET_ANNOUNCEMENTS_LOADING = "GET_ANNOUNCEMENTS_LOADING";
export const GET_ANNOUNCEMENTS_SUCCESS = "GET_ANNOUNCEMENTS_SUCCESS";
export const GET_ANNOUNCEMENTS_ERROR = "GET_ANNOUNCEMENTS_ERROR";

export const READ_ANNOUNCEMENTS_LOADING = "READ_ANNOUNCEMENTS_LOADING";
export const READ_ANNOUNCEMENTS_SUCCESS = "READ_ANNOUNCEMENTS_SUCCESS";
export const READ_ANNOUNCEMENTS_ERROR = "READ_ANNOUNCEMENTS_ERROR";

const getAnnouncementsLoading = () => ({
  type: GET_ANNOUNCEMENTS_LOADING,
});

const getAnnouncementsSuccess = (postsData) => ({
  type: GET_ANNOUNCEMENTS_SUCCESS,
  payload: postsData,
});

const getAnnouncementsError = (error) => ({
  type: GET_ANNOUNCEMENTS_ERROR,
  payload: {
    error,
  },
});

const readAnnouncementsLoading = () => ({
  type: READ_ANNOUNCEMENTS_LOADING,
});

const readAnnouncementsSuccess = () => ({
  type: READ_ANNOUNCEMENTS_SUCCESS,
});

const readAnnouncementsError = (error) => ({
  type: READ_ANNOUNCEMENTS_ERROR,
  payload: {
    error,
  },
});

export const getAnnouncements = () => {
  return async (dispatch) => {
    dispatch(getAnnouncementsLoading());
    try {
      const { data } = await API.get("/announcements");
      dispatch(getAnnouncementsSuccess(data));
    } catch (e) {
      if (e?.response?.data) {
        dispatch(getAnnouncementsError(e.response.data));
      } else {
        Logger.error(e);
      }
    }
  };
};

export const readAnnouncements = () => {
  return async (dispatch) => {
    dispatch(readAnnouncementsLoading());
    try {
      await API.post("/announcements/all/read");
      dispatch(readAnnouncementsSuccess());
    } catch (e) {
      if (e?.response?.data) {
        dispatch(readAnnouncementsError(e.response.data));
      } else {
        Logger.error(e);
      }
    }
  };
};
