import { S3Upload, S3UploadWithoutCallback } from "../../utils/S3Upload";
import { setInfoPopup } from "./infoPopupAction";
import { INFO_POPUP_TYPES } from "../../constants";

export const ADD_FILE_PROGRESS = "ADD_FILE_PROGRESS";
export const SET_FILE_PROGRESS = "SET_FILE_PROGRESS";
export const REMOVE_FILE_PROGRESS = "REMOVE_FILE_PROGRESS";

export const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR";

export function addFileProgress(fileName) {
  return {
    type: ADD_FILE_PROGRESS,
    payload: { fileName, progress: 0 },
  };
}

export function setFileProgress(fileName, progress) {
  return {
    type: SET_FILE_PROGRESS,
    payload: { fileName, progress },
  };
}

export function removeFileProgress(fileName) {
  return {
    type: REMOVE_FILE_PROGRESS,
    payload: fileName,
  };
}

export function fileUploadError(error) {
  return {
    type: FILE_UPLOAD_ERROR,
    payload: error,
  };
}

export const uploadToS3 =
  (file, { type, projectId, incognito_mode }, onSuccess, onError) =>
  (dispatch) => {
    dispatch(addFileProgress(file.name));
    return S3Upload(
      file,
      { type: type, projectId: projectId, incognito_mode: incognito_mode },
      (progress) => dispatch(setFileProgress(file.name, progress)),
      (response) => {
        dispatch(removeFileProgress(file.name));
        if (typeof onSuccess === "function") {
          onSuccess(response);
        }
      },
      (error) => {
        dispatch(fileUploadError(error[0]?.error));
        dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
        onError?.();
      },
    );
  };

export const uploadToS3WithCustomCallback =
  (file, { type, projectId, incognito_mode }, onSuccess, onError) =>
  (dispatch) => {
    dispatch(addFileProgress(file.name));

    return S3UploadWithoutCallback(
      file,
      { type: type, projectId: projectId, incognito_mode: incognito_mode },
      (progress) => dispatch(setFileProgress(file.name, progress)),
      (fields) => {
        dispatch(removeFileProgress(file.name));
        if (typeof onSuccess === "function") {
          onSuccess(fields);
        }
      },
      () => {
        dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
        onError?.();
      },
    );
  };
