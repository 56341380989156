import {
  ADD_CHATBOX_CONTACT_ERROR,
  EXIT,
  IF_CHATBOX_CONTACT_OBTAIN,
  MOVE_CHATBOX,
  OBTAIN_CHATBOX_MESSAGE_ERROR,
  OBTAIN_CHATBOX_MESSAGES_DATA,
  OBTAIN_CHATBOX_MESSAGES_ERROR,
  OBTAIN_CHATBOX_USER_DATA,
  OBTAIN_CHATBOX_USER_ERROR,
  SET_CHATBOX_USER,
  START_GET_CHATBOX_MESSAGES,
  START_GET_CHATBOX_USER,
  TOGGLE_CHATBOX,
} from "../actions";

const initialState = {
  isShow: false,
  position: "static",
  currentUser: null,
  MessageListIsLoading: false,
  isCurrentUserLoading: false,
  currentUserData: {},
  currentUserError: [],
  isLoading: false,
  messageList: [],
  nextCursor: null,
  errors: [],
  sendMessageError: [],
  addChatBoxContactError: [],
  ifChatBoxContactObtain: false,
};

export default function chatBoxReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_CHATBOX:
      return { ...state, isShow: action.payload, currentUser: !action.payload ? null : state.currentUser };
    case MOVE_CHATBOX:
      return { ...state, position: action.payload };
    case SET_CHATBOX_USER:
      return { ...state, isShow: true, currentUser: action.payload };
    case START_GET_CHATBOX_MESSAGES:
      return {
        ...state,
        MessageListIsLoading: action.payload,
      };
    case OBTAIN_CHATBOX_MESSAGES_DATA:
      return {
        ...state,
        messageList: action.payload.data,
        nextCursor: action.payload.nextCursor,
        errors: [],
      };
    case OBTAIN_CHATBOX_MESSAGES_ERROR:
      return {
        ...state,
        messageList: [],
        errors: action.payload,
      };
    case START_GET_CHATBOX_USER:
      return { ...state, isCurrentUserLoading: true };
    case OBTAIN_CHATBOX_USER_DATA:
      return { ...state, isCurrentUserLoading: false, currentUserData: action.payload, currentUserError: [] };
    case OBTAIN_CHATBOX_USER_ERROR:
      return { ...state, isCurrentUserLoading: false, currentUserData: {}, currentUserError: action.payload };
    case OBTAIN_CHATBOX_MESSAGE_ERROR:
      return { ...state, sendMessageError: action.payload };
    case ADD_CHATBOX_CONTACT_ERROR:
      return { ...state, addChatBoxContactError: action.payload };
    case IF_CHATBOX_CONTACT_OBTAIN:
      return {
        ...state,
        ifChatBoxContactObtain: action.payload,
      };
    case EXIT:
      return initialState;
    default:
      return state;
  }
}
