import { push } from "connected-react-router";
import { API } from "../../utils/Api.js";
import { closeSocket } from "../../utils/Socket";

import { obtainProfileData } from "./profileAction";

export const SET_AUTH = "SET_AUTH";
export const START_LOGIN = "START_LOGIN";
export const OBTAIN_LOGIN_DATA = "OBTAIN_LOGIN_DATA";
export const OBTAIN_LOGIN_ERROR = "OBTAIN_LOGIN_ERROR";
export const SET_FIRST_LOAD_HELPER = "SET_FIRST_LOAD_HELPER";
export const EXIT = "EXIT";

function startLogin() {
  return { type: START_LOGIN };
}

export function obtainLoginData() {
  return { type: OBTAIN_LOGIN_DATA };
}

function obtainLoginError(error) {
  return {
    type: OBTAIN_LOGIN_ERROR,
    err: true,
    payload: error,
  };
}

export const exit = () => ({ type: EXIT });

// Login action
export function login(email, password) {
  return (dispatch) => {
    dispatch(startLogin());

    return API.post("auth/login", { email, password })
      .then((response) => {
        dispatch(obtainLoginData());
        dispatch(obtainProfileData(response.data.data.account));
        dispatch(push("/feed"));
      })
      .catch((error) => {
        dispatch(obtainLoginError(error));
      });
  };
}

// Logout action
export function logout() {
  localStorage.removeItem(process.env.REACT_APP_AUTH_HEADER);
  return (dispatch) => {
    closeSocket();
    dispatch(setAuth());
  };
}

export function setAuth(force = false) {
  return {
    type: SET_AUTH,
    payload: !force ? !!localStorage.getItem(process.env.REACT_APP_AUTH_HEADER) : false,
  };
}

export function setFirstLoadHelper(isFirstLoad = false) {
  return {
    type: SET_FIRST_LOAD_HELPER,
    payload: isFirstLoad,
  };
}
