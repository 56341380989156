import {
  CLOSE_TASK_ACCESS_ERROR,
  CLOSE_TASK_ACCESS_START,
  CLOSE_TASK_ACCESS_SUCCESS,
  CREATE_TASK_ERROR,
  CREATE_TASK_START,
  CREATE_TASK_SUCCESS,
  GET_PROJECT_TASKS_ERROR,
  GET_PROJECT_TASKS_START,
  GET_PROJECT_TASKS_SUCCESS,
  GET_TASKS_ERROR,
  GET_TASKS_START,
  GET_TASKS_SUCCESS,
  GET_UPCOMING_TASKS_ERROR,
  GET_UPCOMING_TASKS_START,
  GET_UPCOMING_TASKS_SUCCESS,
  UPDATE_TASK_ERROR,
  UPDATE_TASK_START,
  UPDATE_TASK_SUCCESS,
} from "../actions";

const initialState = {
  tasks: [],
  getTasksLoading: false,
  getTasksError: null,

  createTasksLoading: false,
  createTasksError: null,

  updateTasksLoading: false,
  updateTasksError: null,

  projectTasks: {},
  getProjectTasksLoading: false,
  getProjectTasksError: null,

  closeTaskAccessLoading: false,
  closeTaskAccessError: null,

  upcomingTasks: [],
  upcomingTasksCount: null,
  getUpcomingTasksLoading: false,
  getUpcomingTasksError: null,
};

export default function tasksReducerNew(state = initialState, { type, payload }) {
  switch (type) {
    case GET_TASKS_START:
      return {
        ...state,
        getTasksLoading: true,
      };
    case GET_TASKS_SUCCESS:
      return {
        ...state,
        tasks: payload,
        getTasksLoading: false,
        getTasksError: null,
      };
    case GET_TASKS_ERROR:
      return {
        ...state,
        getTasksLoading: false,
        getTasksError: payload,
      };

    case CREATE_TASK_START:
      return {
        ...state,
        createTasksLoading: true,
      };
    case CREATE_TASK_SUCCESS:
      return {
        ...state,
        tasks: [...state.tasks, payload],
        createTasksLoading: false,
        createTasksError: null,
      };
    case CREATE_TASK_ERROR:
      return {
        ...state,
        createTasksLoading: false,
        createTasksError: payload,
      };

    case UPDATE_TASK_START:
      return {
        ...state,
        updateTasksLoading: true,
      };
    case UPDATE_TASK_SUCCESS:
      return {
        ...state,
        tasks: state.tasks.map((task) => (task.id === payload.id ? payload : task)),
        updateTasksLoading: false,
        updateTasksError: null,
      };
    case UPDATE_TASK_ERROR:
      return {
        ...state,
        updateTasksLoading: false,
        updateTasksError: payload,
      };

    case GET_PROJECT_TASKS_START:
      return {
        ...state,
        getProjectTasksLoading: true,
      };
    case GET_PROJECT_TASKS_SUCCESS:
      return {
        ...state,
        projectTasks: { ...state.projectTasks, [payload.projectId]: payload.projectTasks },
        getProjectTasksLoading: false,
        getProjectTasksError: null,
      };
    case GET_PROJECT_TASKS_ERROR:
      return {
        ...state,
        getProjectTasksLoading: false,
        getProjectTasksError: payload,
      };

    case CLOSE_TASK_ACCESS_START:
      return {
        ...state,
        closeTaskAccessLoading: true,
      };
    case CLOSE_TASK_ACCESS_SUCCESS:
      return {
        ...state,
        tasks: state.tasks.map((task) => (task.id === payload.id ? payload : task)),
        closeTaskAccessLoading: false,
        closeTaskAccessError: null,
      };
    case CLOSE_TASK_ACCESS_ERROR:
      return {
        ...state,
        closeTaskAccessLoading: false,
        closeTaskAccessError: payload,
      };

    case GET_UPCOMING_TASKS_START:
      return {
        ...state,
        getUpcomingTasksLoading: true,
      };
    case GET_UPCOMING_TASKS_SUCCESS:
      return {
        ...state,
        upcomingTasks: payload.upcomingTasks,
        upcomingTasksCount: payload.count,
        getUpcomingTasksLoading: false,
        getUpcomingTasksError: null,
      };
    case GET_UPCOMING_TASKS_ERROR:
      return {
        ...state,
        getUpcomingTasksLoading: false,
        getUpcomingTasksError: payload,
      };

    default:
      return state;
  }
}
