import {
  CLOSE_HINT,
  CLOSE_HINT_ERROR,
  EXIT,
  GET_HINTS,
  GET_HINTS_ERROR,
  OBTAIN_PROFILE_DATA,
  OBTAIN_PROFILE_ERROR,
  START_GET_PROFILE,
} from "../actions";

const initialState = {
  isLoading: false,
  errors: [],
  id: null,
  email: null,
  avatar: null,
  first_name: null,
  last_name: null,
  patronymic: null,
  role: null,
  position: null,
  language: null,
  hints: null,
  hintsError: null,
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HINTS:
      return {
        ...state,
        hints: action.payload,
      };
    case GET_HINTS_ERROR:
      return {
        ...state,
        hintsError: action.payload,
      };
    case CLOSE_HINT:
      return {
        ...state,
        hints: action.payload,
      };
    case CLOSE_HINT_ERROR:
      return {
        ...state,
        hintsError: action.payload,
      };
    case START_GET_PROFILE:
      return {
        ...state,
        isLoading: true,
        errors: [],
      };
    case OBTAIN_PROFILE_DATA:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
        errors: [],
      };
    case OBTAIN_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    case EXIT:
      return initialState;
    default:
      return state;
  }
}
