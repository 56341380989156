// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "/": {
    en: {
      title: "Online Dental Platform",
      desc: "Web platform for communication of dentists, dental technicians, digital research centers, 3D printing centers and Cad / Cam companies.",
      keys: "Program for dentistry, communication platform, dental program, web platform, 3d patient card, online viewing of digital dental models",
      url: "/",
      img: require("./metaListAssets/home_page.jpg"),
    },
    de: {
      title: "Zahnärztliche Internet-Plattform",
      desc: "Web-Plattform für die Kommunikation von Zahnärzten, Zahntechnikern, Zentren für digitale Forschung, 3D-Druckzentren und Cad / Cam-Unternehmen.",
      keys: "das Programm für die Zahnmedizin, Kommunikationsplattform, das zahnärztliche Programm, Web-Plattform, 3D-Patientenakte, Online-Ansicht von digitalen Zahnmodellen",
      url: "/",
      img: require("./metaListAssets/home_page.jpg"),
    },
    ru: {
      title: "Стоматологическая интернет платформа",
      desc: "Веб-платформа для коммуникации врачей стоматологов, зубных техников, центров цифровых исследований, центров 3D печати и Cad/Cam компаний.",
      keys: "Программа для стоматологии, коммуникационная платформа, стоматологическая программа, веб платформа, 3d карта пациента, онлайн просмотр цифровых моделей зубов",
      url: "/",
      img: require("./metaListAssets/home_page.jpg"),
    },
    ua: {
      title: "Стоматологічна інтернет-платформа",
      desc: "Веб-платформа для комунікації лікарів-стоматологів, зубних техніків, центрів цифрових досліджень, центрів 3D друку і Cad / Cam компаній.",
      keys: "Програма для стоматології, комунікаційна платформа, стоматологічна програма, веб-платформа, 3d карта пацієнта, онлайн перегляд цифрових моделей зубів",
      url: "/",
      img: require("./metaListAssets/home_page.jpg"),
    },
  },
  "/guide": {
    en: {
      title: "Coleagial Dental Platform Guide",
      desc: "Having studied the manual on the use of the Colleagial dental web platform, you will receive an effective tool for developing your dental business.",
      keys: "manual for using the dental web platform, execution of orthodontic treatment plans, storing digital patient data",
      url: "/guide",
      img: require("./metaListAssets/guide.jpg"),
    },
    de: {
      title: "Coleagial Dental Plattform-Handbuch",
      desc: "Nachdem das Handbuch über die Verwendung des Colleagial Zahn Webplattform studiert, werden Sie ein effektives Werkzeug für die Entwicklung Ihres Dentalgeschäftes erhalten.",
      keys: "handbuch zur verwendung der dental web-plattform, herstellung von kieferorthopädischen behandlungsplänen, speichern digitaler patientendaten",
      url: "/guide",
      img: require("./metaListAssets/guide.jpg"),
    },
    ru: {
      title: "Руководство по использованию стоматологической платформы Coleagial",
      desc: "Изучив пособие по использованию стоматологической веб-платформы Colleagial, Вы получите эффективный инструмент для развития Вашего стоматологического бизнеса.",
      keys: "пособие по использованию стоматологической веб-платформы, оформление планов ортодонтического лечения, хранение цифровых данных пациентов",
      url: "/guide",
      img: require("./metaListAssets/guide.jpg"),
    },
    ua: {
      title: "Посібник з використання стоматологічної платформи Coleagial",
      desc: "Вивчивши посібник з використання стоматологічної веб-платформи Colleagial, Ви отримаєте ефективний інструмент для розвитку Вашого стоматологічного бізнесу.",
      keys: "посібник з використання стоматологічної веб-платформи, оформлення планів ортодонтичного лікування, зберігання цифрових даних пацієнтів",
      url: "/guide",
      img: require("./metaListAssets/guide.jpg"),
    },
  },
  "/our-products": {
    en: {
      title: "Our digital products develop dental business",
      desc: "Colleagial online products are created specifically for professional dentists who want to develop their dental business.",
      keys: "dental web platform, online dental platform, digital patient cards",
      url: "/our-products",
      img: require("./metaListAssets/our_products.jpg"),
    },
    de: {
      title: "Unsere digitalen Produkte entwickeln das zahnärztliche Geschäft",
      desc: "Die Internet-Produkte von Colleagial wurden speziell für Zahnärzte entwickelt, die ihr zahnärztliches Geschäft entwickeln möchten.",
      keys: "die zahnärztliche Web-Plattform, die zahnärztliche Online-Plattform, digitale Patientenakten",
      url: "/our-products",
      img: require("./metaListAssets/our_products.jpg"),
    },
    ru: {
      title: "Наши цифровые продукты развивают стоматологический бизнес",
      desc: "Интернет продукты от Colleagial созданы специально для стоматологов-профессионалов, желающих развить свой стоматологический бизнес.",
      keys: "стоматологическая веб платформа, стоматологическая онлайн платформа, цифровые карты пациентов",
      url: "/our-products",
      img: require("./metaListAssets/our_products.jpg"),
    },
    ua: {
      title: "Наші цифрові продукти розвивають стоматологічний бізнес",
      desc: "Інтернет-продукти від Colleagial створені спеціально для стоматологів-професіоналів, які бажають розвинути свій стоматологічний бізнес.",
      keys: "стоматологічна веб-платформа, стоматологічна онлайн платформа, цифрові карти пацієнтів",
      url: "/our-products",
      img: require("./metaListAssets/our_products.jpg"),
    },
  },
  "/premium-offers": {
    en: {
      title: "Premium offer for dental business owners",
      desc: "The development of the web platform in accordance with the customer’s instructions. The creation of a unique design and functionality for your tasks will help develop your dental business.",
      keys: "CRM system, online lab, CRM system for dentistry, dental business",
      url: "/premium-offers",
      img: require("./metaListAssets/premium_offers.jpg"),
    },
    de: {
      title: "Premium-Angebot für die Inhaber des zahnärztlichen Geschäfts",
      desc: "Die Entwicklung der Web-Plattform nach den Anforderungen des Kunden. Die Erstellung eines einzigartigen Designs und einer Funktionalität für Ihre Aufgaben wird dazu beitragen, Ihr zahnärztliches Geschäft zu entwickeln.",
      keys: "CRM-System, Online-Labor, CRM-System für die Zahnmedizin, das zahnärztliche Geschäft",
      url: "/premium-offers",
      img: require("./metaListAssets/premium_offers.jpg"),
    },
    ru: {
      title: "Премиум предложение для владельцев стоматологического бизнеса",
      desc: "Разработка веб-платформы по заданию заказчика. Создание уникального дизайна и функционала под ваши задачи поможет развить ваш стоматологический бизнес.",
      keys: "Система crm, онлайн лаборатория, crm система для стоматологии, стоматологический бизнес",
      url: "/premium-offers",
      img: require("./metaListAssets/premium_offers.jpg"),
    },
    ua: {
      title: "Преміум пропозиція для власників стоматологічного бізнесу",
      desc: "Розробка веб-платформи за завданням замовника. Створення унікального дизайну і функціоналу під ваші завдання допоможе розвинути ваш стоматологічний бізнес.",
      keys: "Система crm, онлайн лабораторія, crm система для стоматології, стоматологічний бізнес",
      url: "/premium-offers",
      img: require("./metaListAssets/premium_offers.jpg"),
    },
  },
  "/about-us": {
    en: {
      title: "A guide to using the Colleagial dental web platform",
      desc: "A system of reliable storage, systematization and exchange of patient data, the ability to create 3D models of clinical cases, an internal messenger, a appointment journal and many more useful functions can be found by visiting the Colleagial dental web platform.",
      keys: "patient card, event log, dental implantology, secure messenger, messenger, clinical cases in dentistry, stl viewer",
      url: "/about-us",
      img: require("./metaListAssets/about_us.jpg"),
    },
    de: {
      title: "Eine Anleitung zur Verwendung der Colleagial Dental-Webplattform",
      desc: "Ein System zur zuverlässigen Speicherung, Systematisierung und zum Austausch von Patientendaten, die Möglichkeit, 3D-Modelle klinischer Fälle zu erstellen, ein interner Messenger, ein Rezeptjournal und viele weitere nützliche Funktionen finden Sie auf der Colleagial Dental-Webplattform",
      keys: "Patientenkarte, Terminkalender, Messenger, Secure Messenger, 3D-Patientenkarte, 3D-Bildgebung, klinische Fälle in der Zahnheilkunde, Zahnimplantologie",
      url: "/about-us",
      img: require("./metaListAssets/about_us.jpg"),
    },
    ru: {
      title: "Пособие по применению стоматологической web-платформы Colleagial",
      desc: "Система надёжного хранения, систематизации и обмена данными пациентов, возможность создания 3D-моделей клинических случаев, внутренний мессенджер, журнал назначений и ещё множество полезных функций вы найдёте, посетив стоматологическую web-платформу Colleagial.",
      keys: "карта пациента, журнал назначений, мессенджер, безопасный мессенджер, 3D карта пациента, 3D визуализация зубов, клинические случаи в стоматологии, стоматологическая имплантология",
      url: "/about-us",
      img: require("./metaListAssets/about_us.jpg"),
    },
    ua: {
      title: "Посібник по застосуванню стоматологічної web-платформи Colleagial",
      desc: "Система надійного зберігання, систематизації та обміну даними пацієнтів, можливість створення 3D-моделей клінічних випадків, внутрішній месенджер, журнал призначень і ще безліч корисних функцій ви знайдете, відвідавши стоматологічну web-платформу Colleagial.",
      keys: "карта пацієнта, журнал призначень, месенджер, безпечний месенджер, 3D карта пацієнта, 3D візуалізація зубів, клінічні випадки в стоматології, стоматологічна імплантологія",
      url: "/about-us",
      img: require("./metaListAssets/about_us.jpg"),
    },
  },
  "/sign_up": {
    en: {
      title: "User registration",
      desc: "Page for filling in data and registering a user",
      keys: "user registration",
      url: "/sign_up",
      img: require("./metaListAssets/home_page.jpg"),
    },
    de: {
      title: "Benutzer Registration",
      desc: "Seite zum Ausfüllen von Daten und Registrieren eines Benutzers",
      keys: "benutzer registration",
      url: "/sign_up",
      img: require("./metaListAssets/home_page.jpg"),
    },
    ru: {
      title: "Регистрация пользователя",
      desc: "Страница для заполнения данных и регистрации пользователя",
      keys: "регистрация пользователя",
      url: "/sign_up",
      img: require("./metaListAssets/home_page.jpg"),
    },
    ua: {
      title: "Реєстрація користувача",
      desc: "Сторінка для заповнення даних і реєстрації користувача",
      keys: "реєстрація користувача",
      url: "/sign_up",
      img: require("./metaListAssets/home_page.jpg"),
    },
  },
  "/downloadFile": {
    en: {
      title: "Download files",
      desc: "Link for downloading the file by an unregistered user",
      keys: "download",
      url: "/downloadFile",
      img: require("./metaListAssets/downloadFile.jpg"),
    },
    de: {
      title: "Dateien herunterladen",
      desc: "Link zum Herunterladen der Datei durch einen nicht registrierten Benutzer",
      keys: "herunterladen",
      url: "/downloadFile",
      img: require("./metaListAssets/downloadFile.jpg"),
    },
    ru: {
      title: "Скачать файлы",
      desc: "Ссылка для скачивания файла незарегистрированным пользователем",
      keys: "скачать",
      url: "/downloadFile",
      img: require("./metaListAssets/downloadFile.jpg"),
    },
    ua: {
      title: "Завантажити файли",
      desc: "Посилання для скачування файлу незареєстрованим користувачем",
      keys: "скачати",
      url: "/downloadFile",
      img: require("./metaListAssets/downloadFile.jpg"),
    },
  },
  "/feedPreview": {
    en: {
      title: "Patient data preview",
      desc: "Viewing a patient record by an unregistered user",
      keys: "view patient records",
      url: "/feedPreview",
      img: require("./metaListAssets/feedPreview.jpg"),
    },
    de: {
      title: "Vorschau der Patientendaten",
      desc: "Anzeigen einer Patientenakte durch einen nicht registrierten Benutzer",
      keys: "patientenakten",
      url: "/feedPreview",
      img: require("./metaListAssets/feedPreview.jpg"),
    },
    ru: {
      title: "Предварительный просмотр данных пациента",
      desc: "Просмотр карты пациента незарегистрированным пользователем",
      keys: "просматривать карты пациентов",
      url: "/feedPreview",
      img: require("./metaListAssets/feedPreview.jpg"),
    },
    ua: {
      title: "Попередній перегляд даних пацієнтів",
      desc: "Перегляд карти пацієнта незареєстрованим користувачем",
      keys: "переглядати карти пацієнтів",
      url: "/feedPreview",
      img: require("./metaListAssets/feedPreview.jpg"),
    },
  },
};
