import { API } from "../../utils/Api.js";
import { addDays, format } from "date-fns";
import { setInfoPopup } from "./infoPopupAction";
import { INFO_POPUP_TYPES } from "../../constants";

export const OBTAIN_FINANCIAL_DATA = "OBTAIN_FINANCIAL_DATA";
export const OBTAIN_FINANCIAL_ERROR = "OBTAIN_FINANCIAL_ERROR";
export const OBTAIN_FINANCIAL_LOADING = "OBTAIN_FINANCIAL_LOADING";

function obtainFinancialData(data, next, totalSum) {
  return {
    type: OBTAIN_FINANCIAL_DATA,
    payload: { data, next, totalSum },
  };
}

function obtainFinancialLoading(financialListLoading) {
  return {
    type: OBTAIN_FINANCIAL_LOADING,
    payload: financialListLoading,
  };
}

export const getFinancial = (page, query) => (dispatch, getState) => {
  if (page === 1) dispatch(obtainFinancialData([], null, null));
  dispatch(obtainFinancialLoading(true));
  const nextUrl = getState().financial.financialListNext;
  const apiUrl = nextUrl ? nextUrl : "/bill/all";
  const config = {
    params: {
      limit: 10,
      created_at__gte: query?.startDate ? format(new Date(query.startDate), "dd.MM.yyyy") : null,
      created_at__lte: query?.endDate ? format(addDays(new Date(query.endDate), 1), "dd.MM.yyyy") : null,
      cardNumber: query?.cardNumber ? query.cardNumber : null,
      payer: query?.payer ? query.payer : null,
      receiver: query?.receiver ? query.receiver : null,
      billType: query?.billType ? query.billType : null,
      billStatus: query?.billStatus ? query.billStatus : null,
    },
  };

  return API.get(apiUrl, nextUrl ? {} : config)
    .then(({ data }) => {
      const currentFinancialList = [...getState().financial.financialList, ...data.results];
      dispatch(obtainFinancialData(currentFinancialList, data.next, data.total_sum));
    })
    .catch((e) => dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL)))
    .finally(() => dispatch(obtainFinancialLoading(false)));
};
