import { CancelToken } from "axios";
import { API } from "../../utils/Api.js";
import Logger from "../../utils/Logger";

export const OBTAIN_FEED_DATA_LOADING = "OBTAIN_FEED_DATA_LOADING";
export const OBTAIN_FEED_DATA = "OBTAIN_FEED_DATA";
export const OBTAIN_FEED_ERROR = "OBTAIN_FEED_ERROR";
export const TOGGLE_FEED_ACCESS_DIALOG = "TOGGLE_FEED_ACCESS_DIALOG";
export const TOGGLE_FEED_ACCESS_DIALOG_INCOGNITO = "TOGGLE_FEED_ACCESS_DIALOG_INCOGNITO";
export const TOGGLE_FEED_GET_SHARE_LINK_DIALOG = "TOGGLE_FEED_GET_SHARE_LINK_DIALOG";
export const TOGGLE_FEED_GET_SHARE_LINK_DOC_DIALOG = "TOGGLE_FEED_GET_SHARE_LINK_DOC_DIALOG";
export const TOGGLE_FEED_CHANGE_FILE_NAME_DIALOG = "TOGGLE_FEED_CHANGE_FILE_NAME_DIALOG";
export const TOGGLE_CHANGE_FILE_NAME_DIALOG = "TOGGLE_CHANGE_FILE_NAME_DIALOG";
export const TOGGLE_FEED_PREVIEW = "TOGGLE_FEED_PREVIEW";
export const TOGGLE_FEED_PREVIEW_ERROR = "TOGGLE_FEED_PREVIEW_ERROR";
export const OBTAIN_FEED_SEARCH_DATA = "OBTAIN_FEED_SEARCH_DATA";
export const OBTAIN_FEED_SEARCH_CANCEL_TOKEN = "OBTAIN_FEED_SEARCH_CANCEL_TOKEN";
export const OBTAIN_FEED_SEARCH_ERROR = "OBTAIN_FEED_SEARCH_ERROR";
export const OBTAIN_FEED_CONTENT_DATA = "OBTAIN_FEED_CONTENT_DATA";
export const OBTAIN_FEED_CONTENT_ERROR = "OBTAIN_FEED_CONTENT_ERROR";
export const OBTAIN_FEED_CONTENT_DATA_LOADING = "OBTAIN_FEED_CONTENT_DATA_LOADING";
export const SET_FEED_CONTENT_OWNCREATED_ITEMS = "SET_FEED_CONTENT_OWNCREATED_ITEMS";
// export const TOGGLE_PHOTO_PROTOCOL_DIALOG = "TOGGLE_PHOTO_PROTOCOL_DIALOG";
export const TOGGLE_XRAY_PROTOCOL_DIALOG = "TOGGLE_XRAY_PROTOCOL_DIALOG";
export const TOGGLE_PROJECT_SLIDER_DIALOG = "TOGGLE_PROJECT_SLIDER_DIALOG";
export const TOGGLE_IMAGE_PREVIEW = "TOGGLE_IMAGE_PREVIEW";
export const TOGGLE_FEED_DELETE_DIALOG = "TOGGLE_FEED_DELETE_DIALOG";
export const TOGGLE_BILL_DIALOG = "TOGGLE_BILL_DIALOG";
export const TOGGLE_PDF_PREVIEW = "TOGGLE_PDF_PREVIEW";
export const START_FEED_SEARCH_DATA = "START_FEED_SEARCH_DATA";
// export const PROJECT_SLIDER_UPDATE = "PROJECT_SLIDER_UPDATE";
// export const PROJECT_SLIDER_CREATE = "PROJECT_SLIDER_CREATE";
// export const PROJECT_PHOTO_PROTOCOL_UPDATE = "PROJECT_PHOTO_PROTOCOL_UPDATE";
// export const PROJECT_XRAY_PROTOCOL_UPDATE = "PROJECT_XRAY_PROTOCOL_UPDATE";
export const TOGGLE_TREATMENT_PLAN_DIALOG = "TOGGLE_TREATMENT_PLAN_DIALOG";
export const TOGGLE_FORMULA_INITIAL_PREVIEW = "TOGGLE_FORMULA_INITIAL_PREVIEW";
export const SET_FEED_FILTERS_USERS_SUCCESS = "SET_FEED_FILTERS_USERS_SUCCESS";

export const SET_FILES_LOADING = "SET_FILES_LOADING";
export const GET_FILES_SUCCESS = "GET_FILES_SUCCESS";
export const ADD_NEW_FILE = "ADD_NEW_FILE";
export const DELETE_FILE = "DELETE_FILE";
export const CLEAR_LIST = "CLEAR_LIST";

export const PREVIEW_LOADING = "PREVIEW_LOADING";

function obtainFeedDataLoading(isLoading) {
  return {
    type: OBTAIN_FEED_DATA_LOADING,
    payload: isLoading,
  };
}

function obtainFeedData(data, nextCursor) {
  return {
    type: OBTAIN_FEED_DATA,
    payload: { data, nextCursor },
  };
}

function obtainFeedError(error) {
  return {
    type: OBTAIN_FEED_ERROR,
    payload: error,
  };
}

export function toggleFeedPreview(isShow, data = {}) {
  return {
    type: TOGGLE_FEED_PREVIEW,
    payload: { isShow, data },
  };
}

export function toggleFeedPreviewError(isShow) {
  return {
    type: TOGGLE_FEED_PREVIEW_ERROR,
    payload: { isShow },
  };
}

const previewLoading = (loading) => {
  return {
    type: PREVIEW_LOADING,
    payload: loading,
  };
};

function setDeleteFeedDialog({ isShow, feedId, deleteProjectContentId, deleteProjectAppointmentId }) {
  return {
    type: TOGGLE_FEED_DELETE_DIALOG,
    payload: { isShow, feedId, deleteProjectContentId, deleteProjectAppointmentId },
  };
}

function setManageFeedAccessDialog({ isShow, feedId }) {
  return {
    type: TOGGLE_FEED_ACCESS_DIALOG,
    payload: { isShow, feedId },
  };
}

export function toggleGetShareLinkDialog({ isShow, feedId }) {
  return {
    type: TOGGLE_FEED_GET_SHARE_LINK_DIALOG,
    payload: { isShow, feedId },
  };
}

export function toggleGetShareLinkDocDialog({ isShow, feedId, itemId }) {
  return {
    type: TOGGLE_FEED_GET_SHARE_LINK_DOC_DIALOG,
    payload: { isShow, feedId, itemId },
  };
}

function setChangeFeedFileNameDialog({ isShow, feedId }) {
  return {
    type: TOGGLE_FEED_CHANGE_FILE_NAME_DIALOG,
    payload: { isShow, feedId },
  };
}

function obtainFeedSearchData(data, nextCursor) {
  return {
    type: OBTAIN_FEED_SEARCH_DATA,
    payload: { data, nextCursor },
  };
}

function obtainFeedSearchError(error) {
  return {
    type: OBTAIN_FEED_SEARCH_ERROR,
    payload: error,
  };
}

function startFeedSearchLoading(listCardIsLoading, listDocIsLoading) {
  return {
    type: START_FEED_SEARCH_DATA,
    payload: listCardIsLoading,
    listDocIsLoading,
  };
}

function obtainFeedSearchCancelToken(token) {
  return {
    type: OBTAIN_FEED_SEARCH_CANCEL_TOKEN,
    payload: token,
  };
}

function obtainFeedContentData(key, data, nextCursor) {
  return {
    type: OBTAIN_FEED_CONTENT_DATA,
    payload: { key, data, nextCursor },
  };
}

function obtainFeedContentError(key, error) {
  return {
    type: OBTAIN_FEED_CONTENT_ERROR,
    payload: { key, error },
  };
}

function obtainFeedContentDataLoading(feedId, isLoading) {
  return {
    type: OBTAIN_FEED_CONTENT_DATA_LOADING,
    payload: { feedId, isLoading },
  };
}

function setProjectContentNewOwnCreatedItems(feedId, isNew) {
  return {
    type: SET_FEED_CONTENT_OWNCREATED_ITEMS,
    payload: { feedId, isNew },
  };
}

//api
export const getFeedList = (page) => (dispatch, getState) => {
  dispatch(obtainFeedDataLoading(true));
  if (page === 1) {
    dispatch(clearFeedList());
  }
  const nextCursor = getState().feed.feedListNextCursor;
  let users = getState().feed.feedListFilterUsers.map((el) => el.id);
  users = users.length ? users.toString() : null;

  return API.get("/feed/message/all", { params: { cursor: nextCursor, users: users } }).then(
    (response) => {
      const updatedItems = response.data.results;

      const currentFeedList = {
        ...getState().feed.feedList,
        ...updatedItems.reduce((obj, cur) => {
          return { ...obj, [cur.id]: cur };
        }, {}),
      };

      const newNextCursor = response.data.next;

      dispatch(obtainFeedData(currentFeedList, newNextCursor));

      dispatch(obtainFeedDataLoading(false));
    },
    (error) => dispatch(obtainFeedError(error)),
  );
};

export const sendFeedMessage = (message) => () => {
  return API.post("/feed/message/", { text: message }).catch(() => {});
};

export const clearFeedList = () => (dispatch) => dispatch(obtainFeedData({}, null));

export const openFeedPreview = (feedId, onSuccess) => (dispatch, getState) => {
  dispatch(previewLoading(true));

  if (getState().feed.feedList[feedId]) {
    dispatch(previewLoading(false));
    onSuccess?.();
    dispatch(toggleFeedPreview(true, feedId));
    return;
  }

  return API.get("/feed/object/" + feedId).then(
    (response) => {
      const currentFeedList = { ...getState().feed.feedList, [feedId]: response.data };
      const nextCursor = getState().feed.feedListNextCursor;

      dispatch(previewLoading(false));
      onSuccess?.();
      dispatch(obtainFeedData(currentFeedList, nextCursor));

      dispatch(toggleFeedPreview(true, feedId));
    },
    (error) => {
      Logger.info(error);
    },
  );
};

export const getSingleFeed = (id) => (dispatch, getState) => {
  return API.get(`/feed/object/${id}`).then(
    ({ data }) => {
      const currentFeedList = { ...getState().feed.feedList, [id]: data };
      const nextCursor = getState().feed.feedListNextCursor;

      dispatch(obtainFeedData(currentFeedList, nextCursor));
    },
    (error) => {
      dispatch(obtainFeedError(error));
    },
  );
};

export const removeSingleFeed = (id) => (dispatch, getState) => {
  let currentFeedList = { ...getState().feed.feedList };
  delete currentFeedList[id];
  const nextCursor = getState().feed.feedListNextCursor;
  dispatch(obtainFeedData(currentFeedList, nextCursor));
};

export const toggleDeleteFeedDialog =
  ({ isShow, feedId, deleteProjectContentId, deleteProjectAppointmentId }) =>
  (dispatch) => {
    dispatch(
      setDeleteFeedDialog({
        isShow,
        feedId,
        deleteProjectContentId,
        deleteProjectAppointmentId,
      }),
    );
  };

export const deleteFeed = (feedId) => () => {
  return API.delete(`/feed/object/${feedId}`).catch(() => {});
};

export const toggleManageFeedAccessDialog =
  ({ isShow, feedId }) =>
  (dispatch) => {
    if (isShow) {
      dispatch(setManageFeedAccessDialog({ isShow, feedId }));
    } else {
      dispatch(setManageFeedAccessDialog({ isShow, feedId }));
      dispatch(getSingleFeed(feedId));
    }
  };

export const toggleChangeFeedFileNameDialog =
  ({ isShow, feedId }) =>
  (dispatch) => {
    dispatch(setChangeFeedFileNameDialog({ isShow, feedId }));
    if (!isShow) dispatch(getSingleFeed(feedId));
  };

const setLoading = () => ({
  type: SET_FILES_LOADING,
});

const getFiles = (next, list) => ({
  type: GET_FILES_SUCCESS,
  payload: { next, list },
});

export const addNewFile = (file) => (dispatch) =>
  dispatch({
    type: ADD_NEW_FILE,
    payload: file,
  });

export const deleteFile = (id) => (dispatch) => {
  dispatch({
    type: DELETE_FILE,
    payload: id,
  });
};

export const clearList = (list) => (dispatch) => {
  dispatch({
    type: CLEAR_LIST,
    payload: list,
  });
};

export const searchFeedItem =
  ({ page, search, type }) =>
  (dispatch, getState) => {
    switch (type) {
      case "project":
        const currentCancelToken = getState().feed.feedListSearchCancelToken;
        if (currentCancelToken) {
          currentCancelToken();
        }
        if (page === 1) {
          dispatch(obtainFeedSearchData([], null));
        }
        dispatch(startFeedSearchLoading(true, true));
        const nextCursor = getState().feed.feedListSearchNextCursor;
        return API.get("/feed/search", {
          cancelToken: new CancelToken((c) => {
            dispatch(obtainFeedSearchCancelToken(c));
          }),
          params: {
            type: type,
            search: search,
            cursor: nextCursor,
          },
        }).then(
          (response) => {
            const newNextCursor = response.data.next;
            const currentSearchFeedList = [...getState().feed.feedListSearch, ...response.data.results];
            dispatch(obtainFeedSearchData(currentSearchFeedList, newNextCursor));
            dispatch(startFeedSearchLoading(false, false));
          },
          (error) => {
            dispatch(obtainFeedSearchError(error));
          },
        );
      case "file":
        dispatch(setLoading());
        return API.get("/feed/search", {
          params: {
            type: type,
            search: search,
          },
        }).then((response) => {
          dispatch(getFiles(response.data.next, response.data.results));
        });
      default:
        return false;
    }
  };

export const getProjectContent = (feedId, page) => (dispatch, getState) => {
  if (page === 1) {
    dispatch(obtainFeedContentData(feedId, [], null));
  }

  dispatch(obtainFeedContentDataLoading(feedId, true));

  const nextCursor = getState().feed.feedContentListCursor[feedId];
  const currentFeedContentData = getState().feed.feedContentList[feedId];
  const params = {
    cursor: nextCursor,
    ordering: "-created_at",
  };
  return API.get(`project/${feedId}/objects`, { params })
    .then((response) => {
      const newNextCursor = response.data.next;
      dispatch(
        obtainFeedContentData(
          feedId,
          [...(currentFeedContentData ? currentFeedContentData : []), ...response.data.results],
          newNextCursor,
        ),
      );

      dispatch(obtainFeedContentDataLoading(feedId, false));
    })
    .catch(() => dispatch(obtainFeedContentError(feedId, ["error"])));
};

export const getSingleProjectContent =
  (feedId, itemId, isOwnContent = false) =>
  (dispatch, getState) => {
    Logger.info("======== getSingleProjectContent");
    return API.get(`project/object/${itemId}`)
      .then((response) => {
        const currentFeedList = getState().feed.feedContentList[feedId] ? getState().feed.feedContentList[feedId] : [];
        let updatedFeedList = [...currentFeedList.filter((el) => el.id !== itemId), response.data];
        const nextCursor = getState().feed.feedContentListCursor[feedId];
        // hook for trigger scroll bottom
        if (isOwnContent) {
          dispatch(setProjectContentNewOwnCreatedItems(feedId, true));
        }
        dispatch(obtainFeedContentData(feedId, updatedFeedList, nextCursor));
        if (isOwnContent) {
          dispatch(setProjectContentNewOwnCreatedItems(feedId, false));
        }
      })
      .catch((error) => {
        Logger.error(error);
      });
  };

export const removeSingleProjectContent = (feedId, itemId) => (dispatch, getState) => {
  const contentListOfCurrentFeed = getState().feed.feedContentList[feedId];
  if (contentListOfCurrentFeed) {
    const newFeedContentList = [...contentListOfCurrentFeed.filter((el) => el.id !== itemId)];
    const nextFeedContentCursor = getState().feed.feedContentListCursor[feedId];
    dispatch(obtainFeedContentData(feedId, newFeedContentList, nextFeedContentCursor));
  }
};

export const createProjectContent =
  ({ feedId, message, incognito_mode }) =>
  (dispatch, getState) => {
    return API.post("/project/message/", {
      project: feedId,
      text: message,
      incognito_mode,
    }).then(
      (response) => {
        let currentFeedList = [...getState().feed.feedContentList[feedId], response.data];
        const nextCursor = getState().feed.feedContentListCursor[feedId];
        dispatch(setProjectContentNewOwnCreatedItems(feedId, true));
        dispatch(obtainFeedContentData(feedId, currentFeedList, nextCursor));
        dispatch(setProjectContentNewOwnCreatedItems(feedId, false));
      },
      (error) => {
        Logger.error(error);
      },
    );
  };

export const deleteProjectContent = (feedId, objectId) => () => {
  return API.delete(`/project/object/${objectId}`)
    .then(() => {
      Logger.info("deleteProjectContent success");
    })
    .catch((error) => Logger.error(error));
};

// TODO
export const moveFeed = (feedId, projectId) => () => {
  return API.post(`/feed/object/${feedId}/move/${projectId}`)
    .then((response) => {
      Logger.info("moveFeed.RESPONSE", response);
    })
    .catch((error) => Logger.error(error));
};

// Image Preview
export const toggleImagePreview =
  ({ isShow, imagePreviewArray, imagePreviewIndex, ...rest }) =>
  (dispatch) => {
    return dispatch({
      type: TOGGLE_IMAGE_PREVIEW,
      payload: { isShow, imagePreviewArray, imagePreviewIndex, ...rest },
    });
  };

export const togglePdfPreview =
  ({ isShow, pdfPreviewLink, pdfPreviewTitle }) =>
  (dispatch) => {
    return dispatch({
      type: TOGGLE_PDF_PREVIEW,
      payload: { isShow, pdfPreviewLink, pdfPreviewTitle },
    });
  };

export const toggleTreatmentPlanDialog =
  ({ isShow, feedId, treatmentPlanId }) =>
  (dispatch) => {
    return dispatch({
      type: TOGGLE_TREATMENT_PLAN_DIALOG,
      payload: { isShow, feedId, treatmentPlanId },
    });
  };

export const toggleFormulaInitialPreview = (isShow, item) => (dispatch) => {
  return dispatch({
    type: TOGGLE_FORMULA_INITIAL_PREVIEW,
    payload: { isShow, item },
  });
};

export const createFeedPatient3dModel = (data) => (dispatch, getState) => {
  return API.post("/project/patient_model_3d", data).then(
    (response) => {
      let currentFeedList = [...getState().feed.feedContentList[data.project], response.data];
      const nextCursor = getState().feed.feedContentListCursor[data.project];
      dispatch(obtainFeedContentData(data.project, currentFeedList, nextCursor));
    },
    (error) => {
      Logger.error(error);
    },
  );
};

export const updateFeedPatient3dModel = (itemId, data) => (dispatch, getState) => {
  return API.patch(`/project/patient_model_3d/${itemId}`, data).then(
    (response) => {
      let currentFeedList = getState().feed.feedContentList[data.project].map((el) => {
        if (el.id === response.data.id) {
          return { ...el, ...response.data };
        }
        return el;
      });
      const nextCursor = getState().feed.feedContentListCursor[data.project];
      dispatch(obtainFeedContentData(data.project, currentFeedList, nextCursor));
    },
    (error) => {
      Logger.error(error);
    },
  );
};

export const createFeedPatient3dSetup = (data) => (dispatch, getState) => {
  return API.post("/project/patient_setup_3d", data).then(
    (response) => {
      let currentFeedList = [...getState().feed.feedContentList[data.project], response.data];
      const nextCursor = getState().feed.feedContentListCursor[data.project];
      dispatch(obtainFeedContentData(data.project, currentFeedList, nextCursor));
    },
    (error) => {
      Logger.error(error);
    },
  );
};

export const updateFeedPatient3dSetup = (itemId, data) => (dispatch, getState) => {
  return API.patch(`/project/patient_setup_3d/${itemId}`, data).then(
    (response) => {
      let currentFeedList = getState().feed.feedContentList[data.project].map((el) => {
        if (el.id === response.data.id) {
          return { ...el, ...response.data };
        }
        return el;
      });
      const nextCursor = getState().feed.feedContentListCursor[data.project];
      dispatch(obtainFeedContentData(data.project, currentFeedList, nextCursor));
    },
    (error) => {
      Logger.error(error);
    },
  );
};

export const createFeedPatient3dSlider = (data) => (dispatch, getState) => {
  return API.post("/project/patient_slider_3d", data).then(
    (response) => {
      let currentFeedList = [...getState().feed.feedContentList[data.project], response.data];
      const nextCursor = getState().feed.feedContentListCursor[data.project];
      dispatch(obtainFeedContentData(data.project, currentFeedList, nextCursor));
    },
    (error) => {
      Logger.error(error);
    },
  );
};

export const updateFeedPatient3dSlider = (itemId, data) => (dispatch, getState) => {
  return API.patch(`/project/patient_slider_3d/${itemId}`, data).then(
    (response) => {
      let currentFeedList = getState().feed.feedContentList[data.project].map((el) => {
        if (el.id === response.data.id) {
          return { ...el, ...response.data };
        }
        return el;
      });
      const nextCursor = getState().feed.feedContentListCursor[data.project];
      dispatch(obtainFeedContentData(data.project, currentFeedList, nextCursor));
    },
    (error) => {
      Logger.error(error);
    },
  );
};

function setFeedFilterUsersSuccess(users) {
  return {
    type: SET_FEED_FILTERS_USERS_SUCCESS,
    payload: users,
  };
}

export const updateFeedFilterUsers = (users) => (dispatch) => {
  dispatch(setFeedFilterUsersSuccess(users));
  dispatch(getFeedList(1));
};
