import Logger from "../../utils/Logger";
import { API } from "../../utils/Api";
// import SocketService from "../modules/Socket";
import { getChatList } from "./index";

export const TOGGLE_CHATBOX = "TOGGLE_CHATBOX";
export const MOVE_CHATBOX = "MOVE_CHATBOX";
export const SET_CHATBOX_USER = "SET_CHATBOX_USER";
export const START_GET_CHATBOX_MESSAGES = "START_GET_CHATBOX_MESSAGES";
export const OBTAIN_CHATBOX_MESSAGES_DATA = "OBTAIN_CHATBOX_MESSAGES_DATA";
export const OBTAIN_CHATBOX_MESSAGES_ERROR = "OBTAIN_CHATBOX_MESSAGES_ERROR";
export const START_GET_CHATBOX_USER = "START_GET_CHATBOX_USER";
export const OBTAIN_CHATBOX_USER_DATA = "OBTAIN_CHATBOX_USER_DATA";
export const OBTAIN_CHATBOX_USER_ERROR = "OBTAIN_CHATBOX_USER_ERROR";
export const OBTAIN_CHATBOX_MESSAGE_ERROR = "OBTAIN_CHATBOX_MESSAGE_ERROR";
export const ADD_CHATBOX_CONTACT_ERROR = "ADD_CHATBOX_CONTACT_ERROR";
export const IF_CHATBOX_CONTACT_OBTAIN = "IF_CHATBOX_CONTACT_OBTAIN";

export function toggleChatBox(isOpen) {
  return {
    type: TOGGLE_CHATBOX,
    payload: isOpen,
  };
}

export function moveChatBox(position) {
  return {
    type: MOVE_CHATBOX,
    payload: !position ? "static" : "aside",
  };
}

function setChatBoxUser(userId) {
  return {
    type: SET_CHATBOX_USER,
    payload: userId,
  };
}

export function openChatBox(userId) {
  return (dispatch) => {
    dispatch(setChatBoxUser(userId));
    dispatch(getChatBoxUser());
    dispatch(ifChatBoxContact());
  };
}

function startGetChatBoxUser() {
  return {
    type: START_GET_CHATBOX_USER,
  };
}
function obtainChatBoxUserData(data) {
  return {
    type: OBTAIN_CHATBOX_USER_DATA,
    payload: data,
  };
}
function obtainChatBoxUserError(error) {
  return {
    type: OBTAIN_CHATBOX_USER_ERROR,
    payload: error,
  };
}

export const getChatBoxUser = () => (dispatch, getState) => {
  dispatch(startGetChatBoxUser(true));
  const userId = getState().chatBox.currentUser;

  return API.get("user/" + userId + "/profile").then(
    (response) => {
      Logger.info("chatbox user data", response.data);
      dispatch(obtainChatBoxUserData(response.data));
      dispatch(startGetChatBoxUser(false));
    },
    (error) => {
      Logger.info(error);
      dispatch(obtainChatBoxUserError(error));
    },
  );
};

function addChatBoxContactError(error) {
  return {
    type: ADD_CHATBOX_CONTACT_ERROR,
    payload: error,
  };
}
export const addChatBoxContact = () => (dispatch, getState) => {
  const userId = getState().chatBox.currentUser;

  return API.post("/user/" + userId + "/addcontactrequest").then(
    (response) => {
      Logger.info("chatbox user add in contact", response.data);
      dispatch(getChatBoxUser(response.data));
    },
    (error) => {
      Logger.info("chatbox add contact error", error);
      dispatch(addChatBoxContactError(error));
    },
  );
};

function ifChatBoxContactObtain(isFriend) {
  return {
    type: IF_CHATBOX_CONTACT_OBTAIN,
    payload: isFriend,
  };
}

export const ifChatBoxContact = () => (dispatch, getState) => {
  const userId = getState().chatBox.currentUser;

  return API.get("/user/" + userId + "/isfriend").then(
    (response) => {
      const isFriends = response.data.is_friend;
      Logger.info("chatbox if user in contact", isFriends);
      dispatch(ifChatBoxContactObtain(isFriends));
    },
    (error) => {
      Logger.info("chatbox if contact error", error);
      // dispatch(ifChatBoxContactError(error));
    },
  );
};

function startGetChatBoxMessages(MessageListIsLoading) {
  return {
    type: START_GET_CHATBOX_MESSAGES,
    payload: MessageListIsLoading,
  };
}

function obtainChatBoxMessagesData(data, nextCursor) {
  return {
    type: OBTAIN_CHATBOX_MESSAGES_DATA,
    payload: { data, nextCursor },
  };
}

function obtainChatBoxMessagesError(error) {
  return {
    type: OBTAIN_CHATBOX_MESSAGES_ERROR,
    payload: error,
  };
}

export const getChatBoxMessages = (page) => (dispatch, getState) => {
  dispatch(startGetChatBoxMessages(true));
  if (page === 1) {
    dispatch(obtainChatBoxMessagesData([], null));
  }

  const userId = getState().chatBox.currentUser;
  const nextCursor = getState().chatBox.nextCursor;

  return API.get("/user/" + userId + "/message", { params: { cursor: nextCursor } }).then(
    (response) => {
      Logger.info("chatbox messages", response.data);

      let currentMessageList = [...getState().chatBox.messageList];
      for (let i = 0; i < response.data.results.length; i++) {
        currentMessageList.unshift(response.data.results[i]);
      }
      const newNextCursor = response.data.next;

      dispatch(obtainChatBoxMessagesData(currentMessageList, newNextCursor));
      dispatch(startGetChatBoxMessages(false));
    },
    (error) => {
      Logger.info(error);
      dispatch(obtainChatBoxMessagesError(error));
    },
  );
};

function obtainChatBoxMessageError(error) {
  return {
    type: OBTAIN_CHATBOX_MESSAGE_ERROR,
    payload: error,
  };
}

export const sendChatBoxMessage = (message) => (dispatch, getState) => {
  const userId = getState().chatBox.currentUser;
  const newMessage = {
    text: message,
  };
  return API.post("user/" + userId + "/message", newMessage).then(
    (response) => {
      Logger.info(response.data);
      const currentMessageList = getState().chatBox.messageList;
      const nextCursor = getState().chatBox.nextCursor;
      const messageList = currentMessageList.concat(response.data);

      dispatch(obtainChatBoxMessagesData(messageList, nextCursor));
      dispatch(getChatList());
      // SocketService.sendMessage(userId, message);
    },
    (error) => {
      Logger.info(error);
      dispatch(obtainChatBoxMessageError(error));
    },
  );
};

export const getSingleChatBoxMessage = (messageId) => (dispatch, getState) => {
  const userId = getState().chatBox.currentUser;

  return API.get("user/" + userId + "/message/" + messageId).then(
    (response) => {
      Logger.info(response.data);
      const currentMessageList = getState().chatBox.messageList;
      const nextCursor = getState().chatBox.nextCursor;
      const messageList = currentMessageList.concat(response.data);

      dispatch(obtainChatBoxMessagesData(messageList, nextCursor));
    },
    (error) => {
      Logger.info(error);
      dispatch(obtainChatBoxMessageError(error));
    },
  );
};
