import Logger from "../../utils/Logger";
import { API } from "../../utils/Api";

export const OBTAIN_CHAT_LIST_DATA = "OBTAIN_CHAT_LIST_DATA";
export const OBTAIN_CHAT_LIST_ERROR = "OBTAIN_CHAT_LIST_ERROR";
export const START_CHAT_LIST_DATA = "START_CHAT_LIST_DATA";
export const LOAD_MORE_CHAT_LIST = "LOAD_MORE_CHAT_LIST";
export const CLEAR_CHAT_LIST = "CLEAR_CHAT_LIST";

function obtainChatListData(data, nextCursor, count) {
  return {
    type: OBTAIN_CHAT_LIST_DATA,
    payload: { data, nextCursor, count },
  };
}
function obtainChatListError(error) {
  return {
    type: OBTAIN_CHAT_LIST_ERROR,
    payload: error,
  };
}
function startChatListLoading(loading) {
  return {
    type: START_CHAT_LIST_DATA,
    payload: loading,
  };
}

function loadMore(results, next) {
  return {
    type: LOAD_MORE_CHAT_LIST,
    results,
    next,
  };
}

export function clearChatList() {
  return {
    type: CLEAR_CHAT_LIST,
  };
}

export const getChatList =
  (page = 1, search = "") =>
  (dispatch) => {
    dispatch(startChatListLoading(true));
    if (page === 1) {
      dispatch(obtainChatListData([], null));
    }
    return API.get("/contacts", {
      params: {
        limit: 20,
        search: search,
      },
    }).then(
      (response) => {
        dispatch(obtainChatListData(response.data.results, response.data.next, response.data.count));
        dispatch(startChatListLoading(false));
      },
      (error) => {
        dispatch(obtainChatListError(error));
      },
    );
  };

export const loadMoreChatList = () => (dispatch, getState) => {
  API.get(getState().chatList.chatListCursor)
    .then(({ data }) => {
      dispatch(loadMore(data.results, data.next));
    })
    .catch((error) => {
      Logger.info(error);
    });
};

export const resetUnreadPM = (userId) => (dispatch, getState) => {
  const currentUserList = [...getState().chatList.chatList];
  const resetPMCounterUserList = currentUserList.map((el) => (el.id === userId ? { ...el, unread_pm: 0 } : el));
  dispatch(obtainChatListData(resetPMCounterUserList, null));
};
