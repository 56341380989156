import { API } from "../../utils/Api.js";
import { setAuth } from "./loginAction.js";
import { setInfoPopup } from "./infoPopupAction";
import { INFO_POPUP_TYPES } from "../../constants";

export const START_GET_PROFILE = "START_GET_PROFILE";
export const OBTAIN_PROFILE_DATA = "OBTAIN_PROFILE_DATA";
export const OBTAIN_PROFILE_ERROR = "OBTAIN_PROFILE_ERROR";
export const START_SAVE_PROFILE = "START_SAVE_PROFILE";
export const GET_HINTS = "GET_HINTS";
export const GET_HINTS_ERROR = "GET_HINTS_ERROR";
export const CLOSE_HINT = "CLOSE_HINT";
export const CLOSE_HINT_ERROR = "CLOSE_HINT_ERROR";

// Get hints
export const getHints = () => async (dispatch) => {
  try {
    const { data } = await API.get("user/profilehints");
    return dispatch({
      type: GET_HINTS,
      payload: data,
    });
  } catch (error) {
    return dispatch({
      type: GET_HINTS_ERROR,
      payload: error,
    });
  }
};

// Post hint
export const closeHint = (hintName) => async (dispatch, getState) => {
  try {
    const currentHints = getState().profile.hints.hints_status;
    const { data } = await API.post("user/profilehints", { hints_status: { ...currentHints, [hintName]: true } });

    return dispatch({
      type: CLOSE_HINT,
      payload: data,
    });
  } catch (error) {
    return dispatch({
      type: CLOSE_HINT_ERROR,
      payload: error,
    });
  }
};

function startGetProfile() {
  return {
    type: START_GET_PROFILE,
  };
}

export function obtainProfileData(data) {
  return {
    type: OBTAIN_PROFILE_DATA,
    payload: data,
  };
}

function obtainProfileError(error) {
  return {
    type: OBTAIN_PROFILE_ERROR,
    payload: error,
  };
}

// Get profile
export const getProfile = () => (dispatch) => {
  dispatch(startGetProfile());
  return API.get("auth/profile").then(
    (response) => {
      dispatch(setAuth());
      dispatch(obtainProfileData(response.data));
    },
    (error) => {
      dispatch(setAuth(true));
      dispatch(obtainProfileError(error));
    },
  );
};

function startSaveProfile() {
  return { type: START_SAVE_PROFILE };
}

// Save profile
export const saveProfile = (profileFields, onSuccess) => (dispatch) => {
  dispatch(startSaveProfile());
  const { position } = profileFields;
  return API.put("/auth/profile", { ...profileFields, position: position ? position : 0 }).then(
    (response) => {
      dispatch(obtainProfileData(response.data));
      onSuccess();
    },
    (error) => {
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
      dispatch(obtainProfileError(error));
    },
  );
};
