import {
  ADD_NEW_FILE,
  CLEAR_LIST,
  DELETE_FILE,
  EXIT,
  GET_FILES_SUCCESS,
  OBTAIN_FEED_CONTENT_DATA,
  OBTAIN_FEED_CONTENT_DATA_LOADING,
  OBTAIN_FEED_CONTENT_ERROR,
  OBTAIN_FEED_DATA,
  OBTAIN_FEED_DATA_LOADING,
  OBTAIN_FEED_ERROR,
  OBTAIN_FEED_SEARCH_CANCEL_TOKEN,
  OBTAIN_FEED_SEARCH_DATA,
  OBTAIN_FEED_SEARCH_ERROR,
  PREVIEW_LOADING,
  SET_FEED_CONTENT_OWNCREATED_ITEMS,
  SET_FEED_FILTERS_USERS_SUCCESS,
  SET_FILES_LOADING,
  START_FEED_SEARCH_DATA,
  TOGGLE_BILL_DIALOG,
  TOGGLE_CHANGE_FILE_NAME_DIALOG,
  TOGGLE_FEED_ACCESS_DIALOG,
  TOGGLE_FEED_ACCESS_DIALOG_INCOGNITO,
  TOGGLE_FEED_CHANGE_FILE_NAME_DIALOG,
  TOGGLE_FEED_DELETE_DIALOG,
  TOGGLE_FEED_GET_SHARE_LINK_DIALOG,
  TOGGLE_FEED_GET_SHARE_LINK_DOC_DIALOG,
  TOGGLE_FEED_PREVIEW,
  TOGGLE_FEED_PREVIEW_ERROR,
  TOGGLE_FORMULA_INITIAL_PREVIEW,
  TOGGLE_IMAGE_PREVIEW,
  TOGGLE_PDF_PREVIEW,
  TOGGLE_TREATMENT_PLAN_DIALOG,
} from "../actions";
import {
  UPDATE_IMAGE_IN_SLIDER_START,
  UPDATE_IMAGE_IN_SLIDER_SUCCESS,
  UPDATE_SINGLE_IMAGE_START,
  UPDATE_SINGLE_IMAGE_SUCCESS,
} from "../actions/imageEdit.actions";

const initialState = {
  feedListFilterUsers: [],

  feedList: {},
  feedListError: [],
  feedListNextCursor: null,
  feedListIsLoading: false,

  isFeedAccessDialog: false,
  feedAccessDialogId: null,

  isFeedAccessDialogIncognito: false,
  feedAccessDialogIdIncognito: null,
  appointmentIdIncognito: null,
  senderIncognito: null,
  senderIncognitoId: null,

  isFeedGetShareLinkDialog: false,
  feedShareLinkDialogId: null,

  isFeedGetShareLinkDocDialog: false,
  feedShareLinkDocDialogId: null,
  projectShareLinkDocDialogId: null,

  isDeleteFeedDialog: false,
  deleteFeedDialogId: null,
  deleteProjectContentId: null,
  deleteProjectAppointmentId: null,

  isFeedChangeFileNameDialog: false,
  feedChangeFileNameDialogId: null,

  isChangeFileNameDialog: false,
  ChangeFileNameDialogId: null,

  isFeedPreview: false,
  isFeedPreviewError: false,
  feedPreviewId: null,

  feedListSearch: [],
  feedListSearchNextCursor: null,
  feedListSearchErrors: [],
  feedListSearchCancelToken: null,
  listCardIsLoading: false,
  listDocIsLoading: false,
  isLoading: false,

  feedAccessList: {}, // OPTIONAL

  feedContentList: {},
  feedContentListError: {},
  feedContentListCursor: {},
  feedContentListIsLoading: {},
  feedContentListOwnCreatedItems: {},

  isImagePreview: false,
  imagePreviewArray: [],
  isUpdateSingleImageLoading: false,
  isUpdateImageInSliderLoading: false,

  isBillDialog: false,
  billFeedId: null,
  billId: null,
  billItemId: null,

  isPdfPreview: false,
  pdfPreviewLink: null,
  pdfPreviewTitle: null,

  isTreatmentPlanDialog: false,
  treatmentPlanFeedId: null,
  treatmentPlanId: null,

  isFormulaInitialPreview: false,
  formulaInitialPreviewData: null,

  rightBarFiles: [],
  rightBarFilesNext: null,
  rightBarFilesLoading: false,

  previewLoading: false,
};

export default function feedReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_IMAGE_IN_SLIDER_START:
      return {
        ...state,
        isUpdateImageInSliderLoading: true,
      };
    case UPDATE_IMAGE_IN_SLIDER_SUCCESS:
      return {
        ...state,
        isUpdateImageInSliderLoading: false,
        imagePreviewArray: action.payload.newImagePreviewArray,
      };
    case UPDATE_SINGLE_IMAGE_START:
      return {
        ...state,
        isUpdateSingleImageLoading: true,
      };
    case UPDATE_SINGLE_IMAGE_SUCCESS:
      return {
        ...state,
        isUpdateSingleImageLoading: false,
        imagePreviewArray: [action.payload.imagePreviewArrayNewItem],
      };

    case CLEAR_LIST:
      return {
        ...state,
        [action.payload]: [],
      };
    case ADD_NEW_FILE:
      return {
        ...state,
        rightBarFiles: [action.payload, ...state.rightBarFiles],
      };
    case DELETE_FILE:
      return {
        ...state,
        rightBarFiles: state.rightBarFiles.filter((file) => file.id !== action.payload),
      };
    case SET_FILES_LOADING:
      return {
        ...state,
        rightBarFilesLoading: true,
      };
    case GET_FILES_SUCCESS:
      return {
        ...state,
        rightBarFiles: action.payload.list,
        rightBarFilesNext: action.payload.next,
        rightBarFilesLoading: false,
      };
    case PREVIEW_LOADING:
      return {
        ...state,
        previewLoading: action.payload,
      };
    case OBTAIN_FEED_DATA_LOADING:
      return {
        ...state,
        feedListIsLoading: action.payload,
      };
    case OBTAIN_FEED_DATA:
      return {
        ...state,
        feedList: action.payload.data,
        feedListNextCursor: action.payload.nextCursor,
        feedListError: [],
      };
    case OBTAIN_FEED_ERROR:
      return {
        ...state,
        feedListError: action.payload,
      };

    case TOGGLE_FEED_ACCESS_DIALOG:
      return {
        ...state,
        isFeedAccessDialog: action.payload.isShow,
        feedAccessDialogId: action.payload.feedId,
      };
    case TOGGLE_FEED_ACCESS_DIALOG_INCOGNITO:
      return {
        ...state,
        isFeedAccessDialogIncognito: action.payload.isShow,
        feedAccessDialogIdIncognito: action.payload.feedId,
        appointmentIdIncognito: action.payload.appointmentIdIncognito,
        senderIncognito: action.payload.senderIncognito,
        senderIncognitoId: action.payload.senderIncognitoId,
      };
    case TOGGLE_FEED_GET_SHARE_LINK_DIALOG:
      return {
        ...state,
        isFeedGetShareLinkDialog: action.payload.isShow,
        feedShareLinkDialogId: action.payload.feedId,
      };
    case TOGGLE_FEED_GET_SHARE_LINK_DOC_DIALOG:
      return {
        ...state,
        isFeedGetShareLinkDocDialog: action.payload.isShow,
        feedShareLinkDocDialogId: action.payload.feedId,
        projectShareLinkDocDialogId: action.payload.itemId,
      };
    case TOGGLE_FEED_CHANGE_FILE_NAME_DIALOG:
      return {
        ...state,
        isFeedChangeFileNameDialog: action.payload.isShow,
        feedChangeFileNameDialogId: action.payload.feedId,
      };
    case TOGGLE_CHANGE_FILE_NAME_DIALOG:
      return {
        ...state,
        isChangeFileNameDialog: action.payload.isShow,
        ChangeFileNameDialogId: action.payload.feedId,
      };
    case TOGGLE_FEED_PREVIEW:
      return {
        ...state,
        isFeedPreview: action.payload.isShow,
        feedPreviewId: action.payload.data,
      };
    case TOGGLE_FEED_PREVIEW_ERROR:
      return {
        ...state,
        isFeedPreviewError: action.payload.isShow,
      };
    case START_FEED_SEARCH_DATA:
      return { ...state, listCardIsLoading: action.payload, listDocIsLoading: action.payload };
    case OBTAIN_FEED_SEARCH_CANCEL_TOKEN:
      return { ...state, feedListSearchCancelToken: action.payload };
    case OBTAIN_FEED_SEARCH_DATA:
      return {
        ...state,
        feedListSearch: action.payload.data,
        feedListSearchNextCursor: action.payload.nextCursor,
        feedListSearchErrors: [],
      };
    case OBTAIN_FEED_SEARCH_ERROR:
      return { ...state, feedListSearch: [], feedListSearchNextCursor: null, feedListSearchErrors: action.payload };

    case OBTAIN_FEED_CONTENT_DATA_LOADING:
      return {
        ...state,
        feedContentListIsLoading: {
          ...state.feedContentListIsLoading,
          [action.payload.feedId]: { isLoading: action.payload.isLoading },
        },
      };
    case OBTAIN_FEED_CONTENT_DATA:
      return {
        ...state,
        feedContentList: { ...state.feedContentList, [action.payload.key]: action.payload.data },
        feedContentListCursor: { ...state.feedContentListCursor, [action.payload.key]: action.payload.nextCursor },
      };
    case OBTAIN_FEED_CONTENT_ERROR:
      return {
        ...state,
        feedContentListError: { ...state.feedContentListError, [action.payload.key]: action.payload.error },
      };
    case SET_FEED_CONTENT_OWNCREATED_ITEMS:
      return {
        ...state,
        feedContentListOwnCreatedItems: {
          ...state.feedContentListOwnCreatedItems,
          [action.payload.feedId]: action.payload.isNew,
        },
      };
    // case TOGGLE_PHOTO_PROTOCOL_DIALOG:
    //   return {
    //     ...state,
    //     isPhotoProtocolDialog: action.payload.isShow,
    //     photoProtocolFeedId: action.payload.feedId,
    //     photoProtocolItemId: action.payload.itemId,
    //   };
    // case TOGGLE_XRAY_PROTOCOL_DIALOG:
    //   return {
    //     ...state,
    //     isXRayProtocolDialog: action.payload.isShow,
    //     xrayProtocolFeedId: action.payload.feedId,
    //     xrayProtocolItemId: action.payload.itemId,
    //   };
    // case TOGGLE_PROJECT_SLIDER_DIALOG:
    //   return {
    //     ...state,
    //     isProjectSliderDialog: action.payload.isShow,
    //     projectSliderFeedId: action.payload.feedId,
    //     projectSliderItemId: action.payload.itemId,
    //   };
    // case PROJECT_SLIDER_UPDATE:
    //   return {
    //     ...state,
    //     sliderUpdate: action.payload,
    //   };
    // case PROJECT_SLIDER_CREATE:
    //   return {
    //     ...state,
    //     sliderCreate: action.payload,
    //   };
    // case PROJECT_PHOTO_PROTOCOL_UPDATE:
    //   return {
    //     ...state,
    //     PhotoProtocol: action.payload,
    //   };
    // case PROJECT_XRAY_PROTOCOL_UPDATE:
    //   return {
    //     ...state,
    //     XRayProtocol: action.payload,
    //   };
    case TOGGLE_IMAGE_PREVIEW:
      const { isShow: isImagePreview, ...rest } = action.payload;
      return {
        ...state,
        isImagePreview,
        ...rest,
      };
    case TOGGLE_FEED_DELETE_DIALOG:
      return {
        ...state,
        isDeleteFeedDialog: action.payload.isShow,
        deleteFeedDialogId: action.payload.feedId,
        deleteProjectContentId: action.payload.deleteProjectContentId,
        deleteProjectAppointmentId: action.payload.deleteProjectAppointmentId,
      };
    case TOGGLE_BILL_DIALOG:
      return {
        ...state,
        isBillDialog: action.payload.isShow,
        billFeedId: action.payload.feedId,
        billItemId: action.payload.billId,
      };
    case TOGGLE_PDF_PREVIEW:
      return {
        ...state,
        isPdfPreview: action.payload.isShow,
        pdfPreviewLink: action.payload.pdfPreviewLink,
        pdfPreviewTitle: action.payload.pdfPreviewTitle,
      };
    case TOGGLE_TREATMENT_PLAN_DIALOG:
      return {
        ...state,
        isTreatmentPlanDialog: action.payload.isShow,
        treatmentPlanFeedId: action.payload.feedId,
        treatmentPlanId: action.payload.treatmentPlanId,
      };
    case TOGGLE_FORMULA_INITIAL_PREVIEW:
      return {
        ...state,
        isFormulaInitialPreview: action.payload.isShow,
        formulaInitialPreviewData: action.payload.item,
      };

    case SET_FEED_FILTERS_USERS_SUCCESS:
      return {
        ...state,
        feedListFilterUsers: action.payload,
      };
    case EXIT:
      return initialState;
    default:
      return state;
  }
}
