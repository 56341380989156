import { API } from "../../utils/Api";
import { fileUploadError } from "./fileUploadAction";

export const REPOST_LOADING = "REPOST_LOADING";
export const REPOST_SUCCESS = "REPOST_SUCCESS";
export const REPOST_ERROR = "REPOST_ERROR";

const setRepostLoading = () => ({
  type: REPOST_LOADING,
});

const repostSuccess = () => ({
  type: REPOST_SUCCESS,
});

const repostError = (error) => ({
  type: REPOST_ERROR,
  payload: error,
});

export const repost = (id, onSuccess) => async (dispatch) => {
  dispatch(setRepostLoading());

  try {
    await API.post(`feed/${id}/copy`);
    dispatch(repostSuccess());
    onSuccess();
  } catch (error) {
    const err = error.response ? error.response.data?.data?.error[0]?.message : error[0]?.error;

    dispatch(repostError(error));
    dispatch(fileUploadError(err));
  }
};
