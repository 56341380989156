export const TOGGLE_WINDOW_THUMBNAIL = "TOGGLE_WINDOW_THUMBNAIL";
export const TOGGLE_WINDOW_THUMBNAIL_FULLSCREEN = "TOGGLE_WINDOW_THUMBNAIL_FULLSCREEN";
export const RESET_WINDOW_THUMBNAIL_DATA = "RESET_WINDOW_THUMBNAIL_DATA";
export const SET_3D_DENTAL = "SET_3D_DENTAL";
export const SET_3D_STL = "SET_3D_STL";

function resetWindowThumbnail() {
  return {
    type: RESET_WINDOW_THUMBNAIL_DATA,
  };
}

function setWindowThumbnail(isShow) {
  return {
    type: TOGGLE_WINDOW_THUMBNAIL,
    payload: isShow,
  };
}

export const toggleWindowThumbnail = (isShow) => (dispatch) => {
  if (!isShow) {
    dispatch(resetWindowThumbnail());
  }
  dispatch(setWindowThumbnail(isShow));
};

export function toggleWindowThumbnailFullscreen(isMini) {
  return {
    type: TOGGLE_WINDOW_THUMBNAIL_FULLSCREEN,
    payload: isMini,
  };
}

function set3dStlDentalModels(models) {
  return {
    type: SET_3D_DENTAL,
    payload: models,
  };
}

export const view3dDental = (data) => (dispatch) => {
  dispatch(set3dStlDentalModels(data));
  dispatch(toggleWindowThumbnail(true));
};

function set3dStlModels(models) {
  return {
    type: SET_3D_STL,
    payload: models,
  };
}

export const stl3dPreviewAdd =
  ({ name, url }) =>
  (dispatch, getState) => {
    const currentModels = getState().windowThumbnail.stlData;
    dispatch(set3dStlModels([...currentModels, { name, url, displayName: name }]));
    dispatch(toggleWindowThumbnail(true));
  };

export const stl3dPreviewRemove = (name) => (dispatch, getState) => {
  const currentModels = getState().windowThumbnail.stlData;
  dispatch(set3dStlModels([...currentModels.filter((el) => el.displayName !== name)]));
};

export const stl3dPreviewAddSet = (stlFiles) => (dispatch, getState) => {
  dispatch(set3dStlModels([...stlFiles]));
  dispatch(toggleWindowThumbnail(true));
};
// export function view3d(url, name, material, opacity) {
//   return {
//     type: VIEW_3D,
//     payload: [
//       {
//         url,
//         name,
//         options: {
//           material: material ? material : "orange",
//           opacity: opacity ? opacity : 100,
//         },
//         progress: 0,
//       },
//     ],
//   };
// }
