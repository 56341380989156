import Logger from "../../utils/Logger";

function setDialog(dialogName, isShow, data, onSubmit, onClose) {
  return {
    type: dialogName,
    isShow: isShow,
    data: isShow ? data : null,
    onSubmit: onSubmit,
    onClose: onClose,
  };
}

export const toggleGlobalDialog =
  (dialogName, isShow, data = null, onSubmit = null, onClose = null) =>
  (dispatch) => {
    Logger.info(`SetDialog "${dialogName}": state: "${isShow}" with data`, data);
    dispatch(setDialog(dialogName, isShow, data, onSubmit, onClose));
  };
