import { EXIT, TOGGLE_TOP_BAR } from "../actions";

const initialState = {
  isSideBarClosed: true,
};

export default function topBarReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_TOP_BAR:
      return { ...state, isSideBarClosed: action.payload };
    case EXIT:
      return initialState;
    default:
      return state;
  }
}
