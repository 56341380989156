import {
  GET_ANNOUNCEMENTS_ERROR,
  GET_ANNOUNCEMENTS_LOADING,
  GET_ANNOUNCEMENTS_SUCCESS,
  READ_ANNOUNCEMENTS_ERROR,
  READ_ANNOUNCEMENTS_LOADING,
  READ_ANNOUNCEMENTS_SUCCESS,
} from "../actions";

const initialState = {
  announcements: null,
  getAnnouncementsLoading: false,
  getAnnouncementsError: false,
  readAnnouncementsLoading: false,
  readAnnouncementsError: false,
};

export default function announcementsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_ANNOUNCEMENTS_LOADING:
      return {
        ...state,
        getAnnouncementsLoading: true,
      };
    case GET_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        getAnnouncementsLoading: false,
        getAnnouncementsError: null,
        announcements: payload,
      };
    case GET_ANNOUNCEMENTS_ERROR:
      return {
        ...state,
        getAnnouncementsLoading: false,
        getAnnouncementsError: payload.error,
      };
    case READ_ANNOUNCEMENTS_LOADING:
      return {
        ...state,
        readAnnouncementsLoading: true,
      };
    case READ_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        announcements: null,
        readAnnouncementsLoading: false,
      };
    case READ_ANNOUNCEMENTS_ERROR:
      return {
        ...state,
        readAnnouncementsLoading: false,
        readAnnouncementsError: payload.error,
      };
    default:
      return state;
  }
}
