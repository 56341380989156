import { API } from "../../utils/Api";
import Logger from "../../utils/Logger";
import { setInfoPopup } from "./infoPopupAction";
import { INFO_POPUP_TYPES } from "../../constants";
import { addDays, endOfISOWeek, format, parse, startOfISOWeek } from "date-fns";
import { GET_PARAMS_TASKS } from "../../components/@Tasks/constants";

function transformDataForTaskPage(data) {
  return Array.isArray(data)
    ? data.map((el) => {
        el.title = el.description;
        el.start = new Date(el.datetime);
        el.end = new Date(el.datetime);
        el.allDay = true;
        return el;
      })
    : {
        ...data,
        title: data.description,
        start: new Date(data.datetime),
        end: new Date(data.datetime),
        allDay: true,
      };
}

export const GET_TASKS_START = "GET_TASKS_START";
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS";
export const GET_TASKS_ERROR = "GET_TASKS_ERROR";

// ============ getTasks
export const getTasksStart = () => ({
  type: GET_TASKS_START,
});

export const getTasksSuccess = (tasks) => ({
  type: GET_TASKS_SUCCESS,
  payload: tasks,
});

export const getTasksError = (error) => ({
  type: GET_TASKS_ERROR,
  payload: error,
});

export const getTasks = (qs) => {
  const queryHelper = new URLSearchParams(qs);
  const currentDate = parse(queryHelper.get(GET_PARAMS_TASKS.DATE), "dd.MM.yy", new Date());

  const dateParams = queryHelper.has(GET_PARAMS_TASKS.DATE)
    ? {
        datetime__gte: format(startOfISOWeek(currentDate), "yyyy-MM-dd"),
        datetime__lte: format(addDays(endOfISOWeek(currentDate), 1), "yyyy-MM-dd"),
      }
    : {};

  queryHelper.delete(GET_PARAMS_TASKS.DATE);

  const apiUrl = `/appointments${qs ? `?${queryHelper.toString()}` : ""}`;
  return async (dispatch) => {
    dispatch(getTasksStart());

    try {
      const { data } = await API.get(apiUrl, { params: dateParams });
      dispatch(getTasksSuccess(transformDataForTaskPage(data.results)));
    } catch (error) {
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
      dispatch(getTasksError(error?.[0]?.error));
    }
  };
};

// ============ getProjectTasks
export const GET_PROJECT_TASKS_START = "GET_PROJECT_TASKS_START";
export const GET_PROJECT_TASKS_SUCCESS = "GET_PROJECT_TASKS_SUCCESS";
export const GET_PROJECT_TASKS_ERROR = "GET_PROJECT_TASKS_ERROR";

export const getProjectTasksStart = () => ({
  type: GET_PROJECT_TASKS_START,
});

export const getProjectTasksSuccess = (projectId, projectTasks) => ({
  type: GET_PROJECT_TASKS_SUCCESS,
  payload: { projectId, projectTasks },
});

export const getProjectTasksError = (error) => ({
  type: GET_PROJECT_TASKS_ERROR,
  payload: error,
});

export const getProjectTasks = (projectId) => {
  const apiUrl = `/appointments?project=${projectId}`;

  return async (dispatch) => {
    dispatch(getProjectTasksStart());

    try {
      const { data } = await API.get(apiUrl);
      const sortedByDate = data.results.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
      dispatch(getProjectTasksSuccess(projectId, sortedByDate));
    } catch (error) {
      Logger.error(error[0]?.error);
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
      dispatch(getProjectTasksError(error[0]?.error));
    }
  };
};

// ============ createTask
export const CREATE_TASK_START = "CREATE_TASK_START";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_ERROR = "CREATE_TASK_ERROR";

export const createTaskStart = () => ({
  type: CREATE_TASK_START,
});

export const createTaskSuccess = (newTaskDTO) => ({
  type: CREATE_TASK_SUCCESS,
  payload: newTaskDTO,
});

export const createTaskError = (error) => ({
  type: CREATE_TASK_ERROR,
  payload: error,
});

export const createTask = (newTask, onSuccess) => {
  const apiUrl = "/appointments";

  return async (dispatch) => {
    dispatch(createTaskStart());

    try {
      const { data: newTaskDTO } = await API.post(apiUrl, newTask);
      dispatch(createTaskSuccess(transformDataForTaskPage(newTaskDTO)));
      onSuccess?.();
    } catch (error) {
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
      dispatch(createTaskError(error?.[0]?.error));
    }
  };
};

// ============ updateTask
export const UPDATE_TASK_START = "UPDATE_TASK_START";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_ERROR = "UPDATE_TASK_ERROR";

export const updateTaskStart = () => ({
  type: UPDATE_TASK_START,
});

export const updateTaskSuccess = (updatedTaskDTO) => ({
  type: UPDATE_TASK_SUCCESS,
  payload: updatedTaskDTO,
});

export const updateTaskError = (error) => ({
  type: UPDATE_TASK_ERROR,
  payload: error,
});

export const updateTask = (updatedTask, onSuccess) => {
  const apiUrl = `/appointments/${updatedTask.taskId}`;

  return async (dispatch) => {
    dispatch(updateTaskStart());

    try {
      const { data: updatedTaskDTO } = await API.patch(apiUrl, updatedTask);
      dispatch(updateTaskSuccess(transformDataForTaskPage(updatedTaskDTO)));
      onSuccess?.();
    } catch (error) {
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
      dispatch(updateTaskError(error[0]?.error));
    }
  };
};

// ============ deleteTask
export const DELETE_TASK_START = "DELETE_TASK_START";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_ERROR = "DELETE_TASK_ERROR";

export const deleteTaskStart = () => ({
  type: DELETE_TASK_START,
});

export const deleteTaskSuccess = () => ({
  type: DELETE_TASK_SUCCESS,
});

export const deleteTaskError = (error) => ({
  type: DELETE_TASK_ERROR,
  payload: error,
});

export const deleteTask = (taskId) => {
  const apiUrl = `/appointments/${taskId}`;

  return async (dispatch) => {
    dispatch(deleteTaskStart());

    try {
      await API.delete(apiUrl);
      dispatch(deleteTaskSuccess());
    } catch (error) {
      Logger.error(error[0]?.error);
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
      dispatch(deleteTaskError(error[0]?.error));
    }
  };
};

// ============ closeTaskAccess
export const CLOSE_TASK_ACCESS_START = "CLOSE_TASK_ACCESS_START";
export const CLOSE_TASK_ACCESS_SUCCESS = "CLOSE_TASK_ACCESS_SUCCESS";
export const CLOSE_TASK_ACCESS_ERROR = "CLOSE_TASK_ACCESS_ERROR";

export const closeTaskAccessStart = () => ({
  type: CLOSE_TASK_ACCESS_START,
});

export const closeTaskAccessSuccess = (updatedTaskDTO) => ({
  type: CLOSE_TASK_ACCESS_SUCCESS,
  payload: updatedTaskDTO,
});

export const closeTaskAccessError = (error) => ({
  type: CLOSE_TASK_ACCESS_ERROR,
  payload: error,
});

export const closeTaskAccess = (feedId, taskId, userId) => {
  const apiUrl = `/appointments/${taskId}/remove/${userId}`;

  return async (dispatch) => {
    dispatch(closeTaskAccessStart());

    try {
      const { data: updatedTaskDTO } = await API.patch(apiUrl);
      dispatch(closeTaskAccessSuccess(transformDataForTaskPage(updatedTaskDTO)));
      if (!!feedId) {
        dispatch(getProjectTasks(feedId));
      }
    } catch (error) {
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
      dispatch(closeTaskAccessError("closeTaskAccess error"));
    }
  };
};

// ============ getUpcomingTasks
export const GET_UPCOMING_TASKS_START = "GET_UPCOMING_TASKS_START";
export const GET_UPCOMING_TASKS_SUCCESS = "GET_UPCOMING_TASKS_SUCCESS";
export const GET_UPCOMING_TASKS_ERROR = "GET_UPCOMING_TASKS_ERROR";

export const getUpcomingTasksStart = () => ({
  type: GET_UPCOMING_TASKS_START,
});

export const getUpcomingTasksSuccess = (upcomingTasks, count) => ({
  type: GET_UPCOMING_TASKS_SUCCESS,
  payload: { upcomingTasks, count },
});

export const getUpcomingTasksError = (error) => ({
  type: GET_UPCOMING_TASKS_ERROR,
  payload: error,
});

export const getUpcomingTasks = () => {
  const apiUrl = "/appointments";
  return async (dispatch) => {
    dispatch(getUpcomingTasksStart());

    try {
      await API.get(apiUrl);
      const { data } = await API.get(apiUrl, {
        params: {
          datetime__gte: format(new Date(), "yyyy-MM-dd"),
          datetime__lte: format(addDays(new Date(), 2), "yyyy-MM-dd"),
        },
      });
      dispatch(getUpcomingTasksSuccess(data.results, data.count));
    } catch (error) {
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
      dispatch(getUpcomingTasksError(error?.[0]?.error));
    }
  };
};
