const dialogsList = [
  "ADD_JOURNAL",
  "BILL_DIALOG",
  "CONFIRM_CLOSE_DIALOG",
  "CONFIRM_DELETE_DIALOG",
  "CONFIRM_CLOSE_JOURNAL_DIALOGS",
  "CHANGE_FILENAME_DIALOG",
  "CREATE_ORDER_PDF_DIALOG",
  "cookies",
  "contactUs",
  "deleteConfirmation",
  "JOURNAL_APPOINTMENTS",
  "JOURNAL_SETTINGS",
  "JOURNAL_SHARE_DIALOG",
  "manageFeedAccess",
  "FEED_ACCESS_DIALOG",
  "FEED_MESSAGE_DIALOG",
  "feedPatient3dModelsDialog",
  "feedPatient3dSetupDialog",
  "feedPatient3dSliderDialog",
  "PATIENT_REPORT_DIALOG",
  "PRICE_LIST_DIALOG",
  "PROJECT_SELECT_DIALOG",
  "PROJECT_ITEM_ACCESS_DIALOG",
  "PROJECT_ITEM_ACCESS_CONFIRM_DIALOG",
  "PROJECT_SLIDER_DIALOG",
  "PHOTO_PROTOCOL_DIALOG",
  "TASKS_DIALOG",
  "TREATMENT_PLAN_DIALOG",
  "termsOfUse",
  "termsConditions",
  "UPDATE_TASK_STATUS",
  "USERS_LIST_DIALOG",
  "XRAY_SLIDER_DIALOG",
];

const initialState = {
  isShow: false,
  data: null,
  onSubmit: null,
  onClose: null,
};

export function createDialogsReducer() {
  const reducers = {};
  dialogsList.forEach((dialogName) => {
    reducers[dialogName] = (state = initialState, action) => {
      if (action.type !== dialogName) {
        return state;
      }
      return {
        ...state,
        isShow: action.isShow,
        data: action.data,
        onSubmit: action.onSubmit,
        onClose: action.onClose,
      };
    };
  });
  return reducers;
}
