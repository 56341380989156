import { moveChatBox } from "./index";

export const TOGGLE_RIGHT_BAR = "TOGGLE_RIGHT_BAR";

export function switchRightBar(isOpen, type = "") {
  return (dispatch) => {
    dispatch(moveChatBox(isOpen));
    dispatch(toggleRightBar(isOpen, type));
  };
}

function toggleRightBar(isOpen, type) {
  return {
    type: TOGGLE_RIGHT_BAR,
    payload: { isOpen, type },
  };
}
