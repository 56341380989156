export const STATUSES = (_t) => ({
  IN_QUEUE: {
    value: "IN_QUEUE",
    label: _t("В очереди"),
  },
  IN_PROGRESS: {
    value: "IN_PROGRESS",
    label: _t("Выполняется"),
  },
  DONE: {
    value: "DONE",
    label: _t("Выполнено"),
  },
  REJECTED: {
    value: "REJECTED",
    label: _t("Отклонено"),
  },
});

export const GET_PARAMS_TASKS = {
  PARTICIPANT: "participant",
  PATIENT: "user",
  DATE: "date",
  TASK: "task",
  STATUS: "status",
};
