import { ADD_FILE_PROGRESS, EXIT, FILE_UPLOAD_ERROR, REMOVE_FILE_PROGRESS, SET_FILE_PROGRESS } from "../actions";

const initialState = {
  isOpen: false,
  fileUploadList: [],
  fileUploadError: "",
};

export default function fileUploadReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_FILE_PROGRESS:
      return {
        ...state,
        isOpen: true,
        fileUploadList: [...state.fileUploadList, action.payload],
      };
    case SET_FILE_PROGRESS: {
      const fileList = [...state.fileUploadList];
      const index = state.fileUploadList.findIndex((item) => item.fileName === action.payload.fileName);
      fileList[index].progress = action.payload.progress;
      return { ...state, fileUploadList: fileList };
    }
    case REMOVE_FILE_PROGRESS: {
      const fileUploadList = state.fileUploadList.filter((item) => item.fileName !== action.payload);
      return {
        ...state,
        fileUploadList,
      };
    }

    case FILE_UPLOAD_ERROR:
      return {
        ...state,
        isOpen: false,
        fileUploadList: [],
        fileUploadError: action.payload,
      };

    case EXIT:
      return initialState;
    default:
      return state;
  }
}
