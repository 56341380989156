import {
  ADD_JOURNAL_ERROR,
  ADD_JOURNAL_START,
  ADD_JOURNAL_SUCCESS,
  CLEAR_ALL_EVENTS,
  CLEAR_ALL_JOURNALS,
  CREATE_EVENTS_ERROR,
  CREATE_EVENTS_START,
  CREATE_EVENTS_SUCCESS,
  DELETE_EVENTS_ERROR,
  DELETE_EVENTS_START,
  DELETE_EVENTS_SUCCESS,
  GET_EVENTS_ERROR,
  GET_EVENTS_START,
  GET_EVENTS_SUCCESS,
  GET_JOURNALS_ERROR,
  GET_JOURNALS_START,
  GET_JOURNALS_SUCCESS,
  TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_ERROR,
  TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_START,
  TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_SUCCESS,
  UPDATE_EVENTS_ERROR,
  UPDATE_EVENTS_START,
  UPDATE_EVENTS_SUCCESS,
  UPDATE_JOURNAL_ERROR,
  UPDATE_JOURNAL_START,
  UPDATE_JOURNAL_SUCCESS,
} from "../actions";

const initialState = {
  getEventsLoading: false,
  getEventsError: null,
  events: [],

  createEventsLoading: false,
  createEventsError: null,

  updateEventsLoading: false,
  updateEventsError: null,

  deleteEventsLoading: false,
  deleteEventsError: null,

  getJournalsLoading: false,
  journals: [],
  updateJournalLoading: false,
  addJournalLoading: false,
  addUserToJournalAccessListLoading: false,
};

export default function journalReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_EVENTS_START:
      return { ...state, getEventsLoading: true };
    case GET_EVENTS_SUCCESS:
      return { ...state, getEventsLoading: false, getEventsError: null, events: payload };
    case GET_EVENTS_ERROR:
      return { ...state, getEventsLoading: false, getEventsError: payload };

    case CREATE_EVENTS_START:
      return { ...state, createEventsLoading: true };
    case CREATE_EVENTS_SUCCESS:
      return { ...state, createEventsLoading: false, createEventsError: null, events: [...state.events, payload] };
    case CREATE_EVENTS_ERROR:
      return { ...state, createEventsLoading: false, createEventsError: payload };

    case UPDATE_EVENTS_START:
      return {
        ...state,
        updateEventsLoading: true,
        events: state.events.map((event) => (event.id === payload.id ? { ...event, ...payload } : event)),
      };
    case UPDATE_EVENTS_SUCCESS:
      return {
        ...state,
        updateEventsLoading: false,
        updateEventsError: null,
        events: Boolean(payload.currentRoom)
          ? state.events
              .filter((event) => {
                return Number(event.room) === Number(payload.currentRoom);
              })
              .map((event) => {
                return event.id === payload.updatedEvent.id ? payload.updatedEvent : event;
              })
          : state.events.map((event) => {
              return event.id === payload.updatedEvent.id ? payload.updatedEvent : event;
            }),
      };
    case UPDATE_EVENTS_ERROR:
      return { ...state, updateEventsLoading: false, updateEventsError: payload };

    case DELETE_EVENTS_START:
      return { ...state, deleteEventsLoading: true };
    case DELETE_EVENTS_SUCCESS:
      return {
        ...state,
        deleteEventsLoading: false,
        deleteEventsError: null,
        events: state.events.filter((event) => event.id !== payload),
      };
    case DELETE_EVENTS_ERROR:
      return { ...state, deleteEventsLoading: false, deleteEventsError: payload };

    case GET_JOURNALS_START:
      return { ...state, getJournalsLoading: true };
    case GET_JOURNALS_SUCCESS:
      return { ...state, getJournalsLoading: false, journals: payload };
    case GET_JOURNALS_ERROR:
      return { ...state, getJournalsLoading: false };

    case ADD_JOURNAL_START:
      return { ...state, addJournalLoading: true };
    case ADD_JOURNAL_SUCCESS:
      return { ...state, addJournalLoading: false, journals: [...state.journals, payload] };
    case ADD_JOURNAL_ERROR:
      return { ...state, addJournalLoading: false };

    case UPDATE_JOURNAL_START:
      return { ...state, updateJournalLoading: true };
    case UPDATE_JOURNAL_SUCCESS:
      return {
        ...state,
        updateJournalLoading: false,
        journals: state.journals.map((el) => (el.id === payload.id ? payload : el)),
      };
    case UPDATE_JOURNAL_ERROR:
      return { ...state, updateJournalLoading: false };

    case TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_START:
      return { ...state, addUserToJournalAccessListLoading: true };
    case TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_SUCCESS:
      return {
        ...state,
        addUserToJournalAccessListLoading: false,
        journals: state.journals.map((el) => (el.id === payload.id ? payload : el)),
      };
    case TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_ERROR:
      return { ...state, addUserToJournalAccessListLoading: false };

    case CLEAR_ALL_EVENTS:
      return { ...state, events: [] };
    case CLEAR_ALL_JOURNALS:
      return { ...state, journals: [] };

    default:
      return state;
  }
}
