import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import i18next from "../../utils/i18n";
import { useLocation } from "react-router";
import metaList from "../../seo/metaList";

const MetaTags = ({ children }) => {
  const location = useLocation();
  const path = location.pathname;
  const currentLang = i18next.language;

  let base_url = useMemo(() => {
    const base_urls = {
      ru: "https://colleagial.com",
      uk: "https://uk.colleagial.com",
      en: "https://en.colleagial.com",
      de: "https://de.colleagial.com",
    };
    if (base_urls.hasOwnProperty(currentLang)) {
      return base_urls[currentLang];
    }
    return base_urls["ru"];
  }, [currentLang]);

  const tags = useMemo(() => {
    if (metaList.hasOwnProperty(path) && metaList[path].hasOwnProperty(currentLang)) {
      return metaList[path][currentLang];
    }
    return metaList["/"]["ru"];
  }, [currentLang, path]);

  const locale = useMemo(() => {
    const localesList = { ru: "ru_RU", uk: "uk_UK", en: "en_EN", de: "de_DE" };
    if (localesList.hasOwnProperty(currentLang)) {
      return localesList[currentLang];
    }
    return localesList["ru"];
  }, [currentLang]);

  return (
    <Helmet>
      <title>{`Colleagial | ${tags.title}`}</title>
      <meta content={tags.desc} name="description" />
      <meta content={tags.keys} name="keywords" />
      <meta content={locale} property="og:locale" />
      <meta content="article" property="og:type" />
      <meta content={`Colleagial | ${tags.title}`} property="og:title" />
      <meta content={tags.desc} property="og:description" />
      <meta content={`${base_url}${tags.img}`} property="og:image" />
      <meta content={`${base_url}/${tags.url}/`} property="og:url" />
      <meta content={`https://uk.colleagial.com${tags.url}/`} property="og:url:alternate" />
      <meta content={`https://en.meshalign.com${tags.url}/`} property="og:url:alternate" />
      <meta content={`https://de.meshalign.com${tags.url}/`} property="og:url:alternate" />
      {/*<meta property="og:site_name" content={tags.locale} />*/}
      <meta content={tags.hasOwnProperty("robots") ? tags.robots : "index, follow"} name="robots" />
      <link href={`${base_url}/${tags.url}/`} rel="canonical" />
      <link href={`https://uk.colleagial.com${tags.url}/`} hrefLang="ru" rel="alternate" />
      <link href={`https://en.colleagial.com${tags.url}/`} hrefLang="ru" rel="alternate" />
      <link href={`https://de.colleagial.com${tags.url}/`} hrefLang="ru" rel="alternate" />
      {children}
    </Helmet>
  );
};

export default MetaTags;
