import { EXIT, TOGGLE_RIGHT_BAR } from "../actions";

const initialState = {
  isOpen: false,
  type: "",
};

export default function rightBarReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_RIGHT_BAR:
      return { ...state, isOpen: action.payload.isOpen, type: action.payload.type };
    case EXIT:
      return initialState;
    default:
      return state;
  }
}
