import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import loginReducer from "./login";
import chatBoxReducer from "./chatBox";
import rightBarReducer from "./rightBar";
import topBarReducer from "./topBar";
import profileReducer from "./profile";
import feedReducer from "./feed.reducer";
import teamReducer from "./team";
import notificationsReducer from "./notifications";
import fileUploadReducer from "./fileUpload";
import windowThumbnailReducer from "./windowThumbnail";
import newProjectReducer from "./newProject";
import chatListReducer from "./chatList";
import administrationReducer from "./administration";
import financialReducer from "./financial.reducer";
import libraryReducer from "./library";
import infoPopupReducer from "./infoPopupReducer";
import repostReducer from "./repostReducer";
import globalLoading from "./globalLoading";
import wizardReducer from "./wizardReducer";
import helpersReducer from "./helpersReducer";
import announcementsReducer from "./announcementsReducer";
import accessReducer from "./access.reducer";
import journalReducer from "./journal.reducer";
import tasksReducer from "./tasks.reducer";
import { createDialogsReducer } from "./dialogs";

const mainReducer = (history) =>
  combineReducers({
    router: connectRouter(history),

    access: accessReducer,
    announcements: announcementsReducer,
    administration: administrationReducer,

    chatBox: chatBoxReducer,
    chatList: chatListReducer,

    feed: feedReducer,
    financial: financialReducer,
    fileUpload: fileUploadReducer,

    globalLoading,

    helpers: helpersReducer,

    infoPopup: infoPopupReducer,

    journal: journalReducer,

    library: libraryReducer,
    login: loginReducer,

    notifications: notificationsReducer,
    newProject: newProjectReducer,

    profile: profileReducer,

    repost: repostReducer,
    rightBar: rightBarReducer,

    tasks: tasksReducer,
    team: teamReducer,
    topBar: topBarReducer,

    windowThumbnail: windowThumbnailReducer,
    wizard: wizardReducer,

    ...createDialogsReducer(),
  });

export default mainReducer;
