import { ADD_TO_CLOSED } from "../actions";

const initialState = {
  wizardClosed: [],
};

export default function wizardReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_TO_CLOSED:
      return { ...state, wizardClosed: [...state.wizardClosed, payload] };
    default:
      return state;
  }
}
