import { API } from "../../utils/Api";
import { setInfoPopup } from "./infoPopupAction";
import { INFO_POPUP_TYPES } from "../../constants";
import { objectToQueryString } from "../../utils/queryStringHelpers";
import { history } from "../store";
import { GET_PARAMS_JOURNAL } from "../../components/@Journal/constants";

function formatDatesAndRoomKeyHelper(events) {
  if (Array.isArray(events)) {
    return events.map((event) => ({
      ...event,
      start: new Date(event.start),
      end: new Date(event.end),
      resourceId: event.room,
    }));
  }

  events.start = new Date(events.start);
  events.end = new Date(events.end);
  events.resourceId = events.room;

  return events;
}

export const GET_EVENTS_START = "GET_EVENTS_START";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_ERROR = "GET_EVENTS_ERROR";

export const CREATE_EVENTS_START = "CREATE_EVENTS_START";
export const CREATE_EVENTS_SUCCESS = "CREATE_EVENTS_SUCCESS";
export const CREATE_EVENTS_ERROR = "CREATE_EVENTS_ERROR";

export const UPDATE_EVENTS_START = "UPDATE_EVENTS_START";
export const UPDATE_EVENTS_SUCCESS = "UPDATE_EVENTS_SUCCESS";
export const UPDATE_EVENTS_ERROR = "UPDATE_EVENTS_ERROR";

export const DELETE_EVENTS_START = "DELETE_EVENTS_START";
export const DELETE_EVENTS_SUCCESS = "DELETE_EVENTS_SUCCESS";
export const DELETE_EVENTS_ERROR = "DELETE_EVENTS_ERROR";

// ============ getJournals
export const GET_JOURNALS_START = "GET_JOURNALS_START";
export const GET_JOURNALS_SUCCESS = "GET_JOURNALS_SUCCESS";
export const GET_JOURNALS_ERROR = "GET_JOURNALS_ERROR";

export const getJournalsStart = () => ({
  type: GET_JOURNALS_START,
});
export const getJournalsSuccess = (journals) => ({
  type: GET_JOURNALS_SUCCESS,
  payload: journals,
});
export const getJournalsError = () => ({
  type: GET_JOURNALS_ERROR,
});

export const getJournals = () => {
  return async (dispatch) => {
    dispatch(getJournalsStart());

    try {
      const { data: journals } = await API.get("/timetable/journal");
      dispatch(getJournalsSuccess(journals));
    } catch (error) {
      dispatch(getJournalsError());
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
    }
  };
};
/**
 * Add journal
 * */
export const ADD_JOURNAL_START = "ADD_JOURNAL_START";
export const ADD_JOURNAL_SUCCESS = "ADD_JOURNAL_SUCCESS";
export const ADD_JOURNAL_ERROR = "ADD_JOURNAL_ERROR";

export const addJournalStart = () => ({
  type: ADD_JOURNAL_START,
});

export const addJournalSuccess = (journal) => ({
  type: ADD_JOURNAL_SUCCESS,
  payload: journal,
});
export const addJournalError = () => ({
  type: ADD_JOURNAL_ERROR,
});

export const addJournal = (newJournal, onSuccess, _t) => {
  const qs = new URLSearchParams(history.location.search);

  return async (dispatch) => {
    dispatch(addJournalStart());
    try {
      const { data: journal } = await API.post("/timetable/journal", newJournal);
      dispatch(addJournalSuccess(journal));
      dispatch(setInfoPopup(INFO_POPUP_TYPES.SUCCESS, _t("Журнал успешно добавлен")));
      qs.set(GET_PARAMS_JOURNAL.JOURNAL_ID, journal.id);
      history.push({ search: qs.toString() });
      onSuccess?.();
    } catch (error) {
      dispatch(addJournalError());
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
    }
  };
};

/**
 * Update journal
 * */
export const UPDATE_JOURNAL_START = "UPDATE_JOURNAL_START";
export const UPDATE_JOURNAL_SUCCESS = "UPDATE_JOURNAL_SUCCESS";
export const UPDATE_JOURNAL_ERROR = "UPDATE_JOURNAL_ERROR";

const updateJournalStart = () => ({
  type: UPDATE_JOURNAL_START,
});

const updateJournalSuccess = (updatedJournal) => ({
  type: UPDATE_JOURNAL_SUCCESS,
  payload: updatedJournal,
});
const updateJournalError = () => ({
  type: UPDATE_JOURNAL_ERROR,
});

export const updateJournal = ({ updatedLocalJournal, journalID, onSuccess, _t }) => {
  return async (dispatch) => {
    dispatch(updateJournalStart());
    try {
      const { data: updatedJournal } = await API.patch(`/timetable/journal/${journalID}`, updatedLocalJournal);
      dispatch(updateJournalSuccess(updatedJournal));
      dispatch(setInfoPopup(INFO_POPUP_TYPES.SUCCESS, _t("Настройки журнала успешно обновлены")));
      onSuccess?.();
    } catch (error) {
      dispatch(updateJournalError());
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
    }
  };
};

/**
 * Delete journal
 * */
export const DELETE_JOURNAL_START = "DELETE_JOURNAL_START";
export const DELETE_JOURNAL_SUCCESS = "DELETE_JOURNAL_SUCCESS";
export const DELETE_JOURNAL_ERROR = "DELETE_JOURNAL_ERROR";

const deleteJournalStart = () => ({
  type: DELETE_JOURNAL_START,
});

const deleteJournalSuccess = (journal) => ({
  type: DELETE_JOURNAL_SUCCESS,
  payload: journal,
});
const deleteJournalError = () => ({
  type: DELETE_JOURNAL_ERROR,
});

export const deleteJournal = (journal_id, onSuccess, _t) => {
  return async (dispatch) => {
    dispatch(deleteJournalStart());
    try {
      await API.delete(`/timetable/journal/${journal_id}`);
      dispatch(deleteJournalSuccess());
      dispatch(setInfoPopup(INFO_POPUP_TYPES.SUCCESS, _t("Журнал успешно удален")));
      onSuccess?.();
    } catch (error) {
      dispatch(deleteJournalError());
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
    }
  };
};
// ============ toggle user in journal access list
export const TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_START = "TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_START";
export const TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_SUCCESS = "TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_SUCCESS";
export const TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_ERROR = "TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_ERROR";

const toggleUserInJournalAccessListStart = () => ({
  type: TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_START,
});
const toggleUserInJournalAccessListSuccess = (updatedJournal) => ({
  type: TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_SUCCESS,
  payload: updatedJournal,
});
const toggleUserInJournalAccessListError = () => ({
  type: TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_ERROR,
});
export const TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_ACTION_TYPES = {
  ADD: "add",
  REMOVE: "remove",
};
export const toggleUserInJournalAccessList = (actionType, journalID, userID, _t, onSuccess) => {
  const successMessage =
    actionType === TOGGLE_USER_IN_JOURNAL_ACCESS_LIST_ACTION_TYPES.ADD
      ? _t("Пользователю успешно открыт доступ")
      : _t("Пользователю успешно закрыт доступ");
  return async (dispatch) => {
    dispatch(toggleUserInJournalAccessListStart());
    try {
      const { data: updatedJournal } = await API.patch(`/timetable/journal/${journalID}/user/${actionType}/${userID}`);
      dispatch(toggleUserInJournalAccessListSuccess(updatedJournal));
      dispatch(setInfoPopup(INFO_POPUP_TYPES.SUCCESS, successMessage));
      onSuccess?.();
    } catch (error) {
      dispatch(toggleUserInJournalAccessListError());
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
    }
  };
};

// ============ getEvents
export const getEventsStart = () => ({
  type: GET_EVENTS_START,
});

export const getEventsSuccess = (events) => ({
  type: GET_EVENTS_SUCCESS,
  payload: events,
});

export const getEventsError = (error) => ({
  type: GET_EVENTS_ERROR,
  payload: error,
});

export const getEvents = ({ journal_id, date_range, start_date, end_date, patient, title, doctor, room }) => {
  const queryParams = { journal_id, date_range, start_date, end_date, patient, title, doctor, room };
  const queryString = objectToQueryString(queryParams);
  const apiUrl = `/timetable${queryString}`;

  return async (dispatch) => {
    dispatch(getEventsStart());

    try {
      const { data: events } = await API.get(apiUrl);
      const eventsWithDate = formatDatesAndRoomKeyHelper(events);

      dispatch(getEventsSuccess(eventsWithDate));
    } catch (error) {
      dispatch(getEventsError(error.response?.data?.message));
    }
  };
};

// ============ createEvents
export const createEventsStart = () => ({
  type: CREATE_EVENTS_START,
});

export const createEventsSuccess = (newEvent) => ({
  type: CREATE_EVENTS_SUCCESS,
  payload: newEvent,
});

export const createEventsError = (error) => ({
  type: CREATE_EVENTS_ERROR,
  payload: error,
});

export const createEvents = (newEvent, onSuccess) => {
  const apiUrl = "/timetable";
  return async (dispatch) => {
    dispatch(createEventsStart());

    try {
      const { data: event } = await API.post(apiUrl, newEvent);
      const formattedEvent = formatDatesAndRoomKeyHelper(event);
      dispatch(createEventsSuccess(formattedEvent));
      onSuccess?.();
      dispatch(setInfoPopup(INFO_POPUP_TYPES.SUCCESS_SAVED));
    } catch (error) {
      dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
      dispatch(createEventsError(error));
    }
  };
};

// ============ updateEvents
const updateEventsStart = (updatedEvent) => ({
  type: UPDATE_EVENTS_START,
  payload: updatedEvent,
});

const updateEventsSuccess = (updatedEvent, currentRoom) => ({
  type: UPDATE_EVENTS_SUCCESS,
  payload: { updatedEvent, currentRoom },
});

const updateEventsError = (error) => ({
  type: UPDATE_EVENTS_ERROR,
  payload: error,
});

export const updateEvents = (updatedEventFromComponent, onSuccess) => async (dispatch, getState) => {
  const query = new URLSearchParams(getState().router.location.search);

  dispatch(updateEventsStart(updatedEventFromComponent));

  try {
    const { data: updatedEvent } = await API.patch(
      `/timetable/${updatedEventFromComponent.id}`,
      updatedEventFromComponent,
    );

    const formattedEvent = formatDatesAndRoomKeyHelper(updatedEvent);
    dispatch(updateEventsSuccess(formattedEvent, query.get("room")));
    dispatch(setInfoPopup(INFO_POPUP_TYPES.SUCCESS_SAVED));
    onSuccess?.();
  } catch (error) {
    dispatch(setInfoPopup(INFO_POPUP_TYPES.ERROR_GLOBAL));
    dispatch(updateEventsError(error));
  }
};

// ============ deleteEvents
const deleteEventsStart = () => ({
  type: DELETE_EVENTS_START,
});

const deleteEventsSuccess = (eventId) => ({
  type: DELETE_EVENTS_SUCCESS,
  payload: eventId,
});

const deleteEventsError = (error) => ({
  type: DELETE_EVENTS_ERROR,
  payload: error,
});

export const deleteEvents = (id, onSuccess) => async (dispatch) => {
  dispatch(deleteEventsStart());

  try {
    await API.delete(`/timetable/${id}`);
    dispatch(deleteEventsSuccess(id));
    onSuccess();
  } catch ({ response }) {
    dispatch(deleteEventsError(response.data));
  }
};

/**
 * Clear all events
 * */
export const CLEAR_ALL_EVENTS = "CLEAR_ALL_EVENTS";
export const clearAllEvents = () => ({
  type: CLEAR_ALL_EVENTS,
});

/**
 * Clear all journals
 * */
export const CLEAR_ALL_JOURNALS = "CLEAR_ALL_JOURNALS";
export const clearAllJournals = () => ({
  type: CLEAR_ALL_JOURNALS,
});
