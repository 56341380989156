import { EXIT } from "../actions";
import { SET_INFO_POPUP } from "../actions/infoPopupAction";

const initialState = {
  type: null,
  message: "",
};

export default function infoPopupReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INFO_POPUP:
      return { ...state, type: action.payload.type, message: action.payload.message };
    case EXIT:
      return initialState;
    default:
      return state;
  }
}
