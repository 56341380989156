import { EXIT, TOGGLE_GLOBAL_LOADER } from "../actions";

const initialState = {
  globalLoading: false,
};

export default function globalLoading(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_GLOBAL_LOADER:
      return { ...state, globalLoading: action.payload };
    case EXIT:
      return initialState;
    default:
      return state;
  }
}
