import { API } from "../../utils/Api";
import Logger from "../../utils/Logger";

export const OBTAIN_TEAM_DATA = "OBTAIN_TEAM_DATA";
export const OBTAIN_TEAM_ERROR = "OBTAIN_TEAM_ERROR";
export const OBTAIN_INVITE_DATA = "OBTAIN_INVITE_DATA";
export const OBTAIN_INVITE_ERROR = "OBTAIN_INVITE_ERROR";
export const OBTAIN_TEAM_DATA_LOADING = "OBTAIN_TEAM_DATA_LOADING";
export const GET_CONTACTS_LENGTH = "GET_CONTACTS_LENGTH";

function obtainTeamData(data, nextCursor, count) {
  return {
    type: OBTAIN_TEAM_DATA,
    payload: { data, nextCursor, count },
  };
}

function obtainTeamDataLoading(teamListLoading) {
  return {
    type: OBTAIN_TEAM_DATA_LOADING,
    payload: teamListLoading,
  };
}

function obtainTeamError(error) {
  return {
    type: OBTAIN_TEAM_ERROR,
    payload: error,
  };
}

function obtainInviteData(data) {
  return {
    type: OBTAIN_INVITE_DATA,
    payload: data,
  };
}

function obtainInviteError(error) {
  return {
    type: OBTAIN_INVITE_ERROR,
    payload: error,
  };
}

export const clearTeamList = () => (dispatch) => dispatch(obtainTeamData([], null));

export const getTeamList =
  (page, search = "") =>
  async (dispatch, getState) => {
    dispatch(obtainTeamDataLoading(true));

    if (page === 1) {
      dispatch(clearTeamList());
    }

    const nextCursor = getState().team.teamListNextCursor;

    try {
      const response = await API.get("/user/contacts", { params: { cursor: nextCursor, search } });
      const teamList = [...getState().team.teamList, ...response.data.results];

      dispatch(obtainTeamData(teamList, response.data.next, response.data.count));

      dispatch(obtainTeamDataLoading(false));
    } catch (error) {
      dispatch(obtainTeamError(error));
    }
  };

export const getInviteList = () => async (dispatch) => {
  try {
    const { data } = await API.get("/user/contactrequests");
    dispatch(obtainInviteData(data));
  } catch (error) {
    dispatch(obtainInviteError(error));
  }
};

export const contactRequest = (type, id) => async (dispatch) => {
  try {
    await API.post(`/user/contactrequest/${id}/${type}`);
    dispatch(getInviteList());
  } catch (error) {
    Logger.error(error);
  }
};

export const deleteContact = (id) => () => API.post(`/user/${id}/removefromcontacts`);
