import * as log from "loglevel";

log.setLevel(process.env.NODE_ENV === "production" ? "silent" : "trace");
const Logger = log;

export default Logger;

// log.trace(msg)
// log.debug(msg)
// log.info(msg)
// log.warn(msg)
// log.error(msg)
