import { IS_NEW_FEED_ITEM_ADDED } from "../actions";

const initialState = {
  isNewFeedItemAdded: false,
};

export default function helpersReducer(state = initialState, action) {
  switch (action.type) {
    case IS_NEW_FEED_ITEM_ADDED:
      return { ...state, isNewFeedItemAdded: action.payload };
    default:
      return state;
  }
}
