import { OBTAIN_ADMINISTRATION_DATA, OBTAIN_ADMINISTRATION_ERROR } from "../actions";

const initialState = {
  administrationList: [],
  administrationListError: [],
  administrationListNextCursor: null,
};

export default function administrationReducer(state = initialState, action) {
  switch (action.type) {
    case OBTAIN_ADMINISTRATION_DATA:
      return {
        ...state,
        administrationList: action.payload.data,
        administrationListError: [],
        administrationListNextCursor: action.payload.nextCursor,
      };
    case OBTAIN_ADMINISTRATION_ERROR:
      return {
        ...state,
        administrationList: [],
        administrationListError: action.payload,
        administrationListNextCursor: null,
      };
    default:
      return state;
  }
}
